import { useContext } from 'react';
import { MyContext } from '../../context/AppContext';

function Switch(className) {
    const [store, setStore] = useContext(MyContext);

    const handleChange = (event) => {
        setStore({ ...store, dark: event.target.checked });
    }

    const handleMouseEnter = () => {
        setStore({ ...store, cursor: true });
    }
    const handleMouseLeave = () => {
        setStore({ ...store, cursor: false });
    }


    return (
        <label className={`switch ml-3 mt-1 ${className ? className : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
            <input type="checkbox" id="switch-theme" onChange={handleChange} checked={store.dark} />
            <span className="slider"></span>
            <span className="light switch-picto"></span>
            <span className="dark switch-picto">
            </span>
        </label >
    );
}

export default Switch;
