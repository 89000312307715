import Personne from "../../assets/img/personne.svg";
import Groupe from "../../assets/img/group.svg";
import Planette from "../../assets/img/planette.svg";
import { Box } from "../base";
import OwlCarousel from 'react-owl-carousel';



const Section5 = () => {
    let data = [
        {
            img: Planette,
            text: "Conscients de la crise environnementale actuelle, nous nous efforçons de promouvoir une culture d'entreprise responsable et soucieuse de l'environnement.",
            title: "Environnementaux",
            imgStyle: "top2",
            titleStyle: "font-weight-bolder pb-2"
        },
        {
            img: Groupe,
            text: "Educacode croit et encourage un équilibre sain entre la vie professionnelle et la vie familiale en mettant en place des pratiques de travail flexibles pour ses employés.",
            title: "Familiaux",
            imgStyle: "top2",
            titleStyle: "font-weight-bolder pb-2"


        },
        {
            img: Personne,
            text: "La réussite de nos formations repose sur l'humain, il nous tient donc à cœur d'offrir un environnement de travail bienveillant, épanouissant et stimulant à nos collaborateurs.",
            title: "Humains",
            imgStyle: "top2",
            titleStyle: "font-weight-bolder pb-2 "

        }
    ]
    return (
        <div className="container-fluid px-0 home-section5">
            <div className="row my-5 p-5 position-relative" >
                <div className="col-12 mb-5">
                    <h2 className="subtitle-underline subtitle">Nos engagements</h2>
                </div>
                <OwlCarousel stageClass="d-flex" items={3} className="owl-theme" nav margin={30} responsive={{
                    0: {
                        items: 1
                    },
                    1200: {
                        items: 3
                    }
                }} >
                    {data.map((elem, index) => <Box img={elem.img} titleStyle={elem.titleStyle} text={elem.text} title={elem.title} imgStyle={elem.imgStyle} key={index} pStyle="mx-5 mx-lg-0" className="col-12 card-invisible border-0 text-center" />
                    )}
                </OwlCarousel>
            </div>
        </div>
    );
}

export default Section5;