import { useRef, useEffect } from "react";

const AsidePage = ({ pageComponent, title, styleType, mobileFull, fixed_title_bottom = "none", scroll = 0.7 }) => {

    const asideTitle = useRef(null)
    useEffect(() => {
        const changeposition = () => {
            var scroll_per_cent = document.documentElement.scrollTop / document.documentElement.offsetHeight;
            if (document.documentElement.scrollTop > 350 && scroll_per_cent <= scroll) {
                asideTitle?.current?.classList.remove("absolute-title")
                asideTitle?.current?.classList.remove(fixed_title_bottom)
                asideTitle?.current?.classList.add("fixed-title")
            }
            else if (scroll_per_cent > scroll) {
                asideTitle?.current?.classList.remove("absolute-title")
                asideTitle?.current?.classList.remove("fixed-title")
                asideTitle?.current?.classList.add(fixed_title_bottom)
            }
            else {
                asideTitle?.current?.classList.remove("fixed-title")
                asideTitle?.current?.classList.remove(fixed_title_bottom)
                asideTitle?.current.classList.add("absolute-title")
            }
        }
        window.addEventListener('scroll', changeposition);
        return () => {
            window.removeEventListener('scroll', changeposition)
        };
    },
        [fixed_title_bottom, scroll]
    );

    return (
        <>
            <div className="row mt-5">
                <div className={mobileFull ? "d-none d-lg-block col-2" : "col-lg-2 col-3"} style={{ zIndex: 2 }}>
                    <h1 className={"aside-title  absolute-title " + styleType} ref={asideTitle}>{title}</h1>
                </div>
                <div className={mobileFull ? "col-12 col-lg-10" : "col-lg-10 col-9"}>
                    {pageComponent}
                </div>
            </div>
        </>
    );
}
export default AsidePage;