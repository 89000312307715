import { ROUTES } from "../../utils";
import { BlueCard } from "../nosMetiers";
import { useNavigate } from "react-router-dom";

const JobDetail = ({ id, title, description }) => {
    const navigate = useNavigate();
    return (
        <div className="mt-5">
            <BlueCard Title={title} Description={description} onClick1={() => navigate(ROUTES.JOBOFFER.replace(":id", id))} onClick2={() => navigate(ROUTES.JOBOFFER.replace(":id", `${id}#candidature`))} LabelButton1="Détails" LabelButton2="Postuler" />
        </div>
    );
}

export default JobDetail;