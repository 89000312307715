import { Button } from "../form";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils";
import EdTech from "../../assets/img/edtech.png";
import VeryUp from "../../assets/img/veryUP.png";
import Emotion from "../../assets/img/emotion.png";
import NanteUniv from "../../assets/img/nantesUniv.png";
import Inpi from "../../assets/img/inpi.png";
import TechnopoleLr from "../../assets/img/logoTechnopole.png";
import Waves from "../../assets/img/waves-system-logo.png";
import Bpi from "../../assets/img/logoBpifrance.png";
import L3i from "../../assets/img/l3i.png";
import { Link } from "../base";

const Section1 = () => {
    let navigate = useNavigate();
    const handleClick = () => {
        navigate(ROUTES.CONTACT);
    }
    return (<>
        <div className="row " style={{ color: "#2A2E61" }}>

            <div className="col-12 col-lg-7 ">
                <p className="text-partenaires"> Educacode est fière et reconnaissante de travailler avec des entreprises expertes dans leurs domaines pour proposer des formations de qualité adaptées aux besoins de nos clients. Il ne serait pas possible d'offrir des services d'une telle qualité et d'évoluer comme nous le faisons aujourd'hui sans ces collaborations et l'expertise de nos partenaires.
                    <br /><br />Si vous êtes une entreprise intéressée pour rejoindre notre réseau, nous serions ravis de discuter avec vous de la possibilité de travailler ensemble.</p>
                <Button type="button" label="Nous contacter" styleType="orange-ctr px-4 my-5" onClick={handleClick}></Button>
            </div>


        </div>
        <div className="ms-lg-5 pb-5">
            <div className="row my-5 mx-5">

                <div className="col-102 pb-5 pb-lg-0 col-lg-4 d-flex justify-content-center">
                    <Link external href="https://edtechfrance.fr/" target="_blank" label={<img src={EdTech} className={"gray img-100"} alt="EdTech" />} />
                </div>
                <div className="col-12 pb-5 pb-lg-0 d-flex justify-content-center align-items-center col-lg-4">
                    <div className=" d-flex align-items-center justify-content-center partenaires-logo">
                        <Link external href="https://www.veryup.com/" target="_blank" label={<img src={VeryUp} className="photos gray img-100" alt="VeryUp" />} />
                    </div>
                </div>
                <div className="col-12 col-lg-4 d-flex justify-content-center align-items-center my-0 my-lg-5">
                    <div className=" partenaires-logo  d-flex align-items-center justify-content-center">
                        <Link external href="https://www.group-emotion.com/?fbclid=IwAR2ObtU-xjHwWJ8lXXxiDgk3f66ecuzSLw1gCF7A8B_cFXuSXxqi6AtqicU" target="_blank" label={<img src={Emotion} className="photos gray img-100" alt="Emotion" />} />
                    </div>
                </div>

                <div className="col-12 col-lg-4 d-flex justify-content-center align-items-center my-5 ">
                    <div className=" partenaires-logo d-flex align-items-center justify-content-center">
                        <Link external href="https://www.univ-nantes.fr/" target="_blank" label={<img src={NanteUniv} className="photos img-100 gray" alt="Nante Université" />} />
                    </div>
                </div>

                <div className="col-12 pb-5 pb-lg-0 col-lg-4 d-flex justify-content-center align-items-center my-0 my-lg-5 ">
                    <div className=" partenaires-logo d-flex align-items-center justify-content-center">
                        <Link external href="https://www.inpi.fr/" target="_blank" label={<img src={Inpi} className="photos img-100 gray" alt="Inpi" />} />
                    </div>
                </div>
                <div className="col-12 col-lg-4 d-flex justify-content-center align-items-center my-0 my-lg-5 ">
                    <div className=" partenaires-logo d-flex align-items-center justify-content-center">
                        <Link external href="https://www.larochelle-technopole.fr/" target="_blank" label={<img src={TechnopoleLr} className="photos img-100 gray" alt="Technopole la Rochelle" />} />
                    </div>
                </div>

                <div className="col-12 col-lg-4 d-flex justify-content-center align-items-center my-5 ">
                    <div className=" partenaires-logo d-flex align-items-center justify-content-center">
                        <Link external href="https://waves-system.com/en/" target="_blank" label={<img src={Waves} className="photos img-100 gray" alt="Waves" />} />
                    </div>
                </div>
                <div className="col-12 pb-5 pb-lg-0 col-lg-4 d-flex justify-content-center align-items-center ">
                    <div className=" partenaires-logo d-flex align-items-center justify-content-center">
                        <Link external href="https://www.bpifrance.fr/ " target="_blank" label={<img src={Bpi} className="photos img-100 gray" alt="BPI" />} />
                    </div>
                </div>
                <div className="col-12 col-lg-4 d-flex justify-content-center align-items-center my-lg-0 ">
                    <div className=" partenaires-logo d-flex align-items-center justify-content-center">
                        <Link external href="https://l3i.univ-larochelle.fr/ " target="_blank" label={<img src={L3i} className="photos img-100 gray" alt="L3i" />} />
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default Section1;