import { Projects } from "../components/nosProjets";
import { Container } from "../components/base";
import { PageTitle } from "../components/base";
import { AsidePage } from "../components/base";
const NosProjets = () => {
    return (<>

        <Container>
            <div className="row" style={{ margin: 20 }}>
                <PageTitle title="nos projets" subTitle="des solutions sur mesure" />
            </div>

            <AsidePage mobileFull={true} title="NOS PROJETS" pageComponent={<Projects />} scroll={0.6} />
        </Container>
    </>);
}

export default NosProjets;