import { Link } from ".";

const MenuItem = ({ isActive, name, link, closeBurger }) => {
    return (
        <li className={`nav-item ${isActive}`}>
            <Link className="nav-link text-white" to={link} onClick={closeBurger}>{name}</Link>
        </li>
    );
}

export default MenuItem;