import { useContext, useEffect } from "react";
import { MyContext } from "../../context/AppContext";

const Theme = ({ children }) => {
    const [store] = useContext(MyContext)

    useEffect(() => {
        var _paq = window._paq = window._paq || [];
        _paq.push(['trackPageView']);
        _paq.push(['enableLinkTracking']);
        (function () {
            var u = "https://matomo.actiroute.com/";
            _paq.push(['setTrackerUrl', u + 'matomo.php']);
            _paq.push(['setSiteId', '6']);
            var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
            g.async = true; g.src = u + 'matomo.js'; s.parentNode.insertBefore(g, s);
        })();
    }, [])

    return (
        <div className={store.dark ? "dark-theme" : ""}>
            {children}
        </div>
    );
}

export default Theme;