import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = (props) => {
    const location = useLocation()

    useEffect(() => {
        if (location.hash.length === 0) {
            window.scrollTo(0, 0);
        } else {
            let elem = document.querySelector(location.hash);
            if (elem) {
                elem.scrollIntoView()
            }
        }
    }, [location]);
    return <>{props.children}</>
};

export default ScrollToTop;
