import triangle from "../../assets/img/triangle.png"
import rectangle from "../../assets/img/rectangle.png"
const MentionsLegales = () => {
    return (<>

        <div className="row mentionLegale-text" style={{ color: "#2A2E61" }}>
            <div className="col-12 col-lg-6">
                <span className="fw-bold " >1 - L'EDITEUR</span><br /><br />
                SASU Educacode SASU au capital de 50 000 €<br />
                Numéro de SIRET : 899081673<br />
                N° de TVA intracommunautaire : FR78899081673<br /><br />

                9 Rue du Cerf-Volant, 17000 La Rochelle<br />
                Téléphone : 02 51 50 07 72,<br />
                Adresse e-mail : contact@educacode.com<br />
                Site Web : Educacode.com<br /><br />

                Président : Polteau Joël

                <br /><br />
                <span className="fw-bold " >2 - HÉBERGEMENT
                </span>                <br /><br />

                Hébergeur : OVH,<br />
                2 rue Kellermann, 59100 Roubaix<br />
                Téléphone : 09 72 10 10 07<br />

                <br /><br />



            </div>
            <div className="col-12 col-lg-6">
                <img src={triangle} style={{ rotate: "20deg" }} alt="triangle" />
            </div>
        </div>
        <div className="row mentionLegale-text pb-5" style={{ color: "#2A2E61" }} >
            <div className="col-12 position-relative" >
                <span className="fw-bold " >3 - ACCES AU SITE</span><br /><br />
                Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption programmée ou non et pouvant découlant d’une nécessité de maintenance.
                <br /><br />
                En cas de modification, interruption ou suspension du Site, l'Editeur ne saurait être tenu responsable.
                <br /><br />

                <span className="fw-bold " >4 - COLLECTE DES DONNEES
                </span>
                <br /><br />

                Le Site assure à l'utilisateur une collecte et un traitement d'informations personnelles dans le respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés.
                <br /><br />

                Le site collecte des données personnelles de deux façons différentes :
                <br /><br />

                - Le formulaire de contact : lorsque l'utilisateur utilise le formulaire de contact disponible sur le site pour nous contacter, certaines informations personnelles peuvent être collectées, telles que le nom, l'adresse e-mail, le numéro de téléphone, etc. Ces informations sont utilisées dans le seul but de répondre aux demandes de l'utilisateur et de lui fournir les informations demandées.
                <br /><br />

                - Le formulaire de candidature : si l'utilisateur postule pour un emploi ou envoie une candidature spontanée via le site, des informations personnelles telles que le CV, la lettre de motivation, les coordonnées de contact, peuvent être collectées. Ces informations sont utilisées dans le cadre du processus de recrutement et de sélection, et sont traitées de manière confidentielle.
                <br /><br />

                En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur peut exercer ce droit en nous contactant et nous indiquant ses coordonnées.

                <br /><br />
                <img src={rectangle} className="d-none d-lg-block" style={{ position: "absolute", bottom: "0px", left: "-40%" }} alt="rectangle" />

                <span className="fw-bold " >5 - DROITS D'AUTEUR
                </span><br /><br />
                Toute utilisation, reproduction, diffusion, commercialisation, modification de tout ou partie du site, sans autorisation de l’éditeur est prohibée et pourra entraîner des actions et poursuites judiciaires telles que celles prévues par le Code de la propriété intellectuelle et le Code civil.
            </div>
        </div>
    </>);
}

export default MentionsLegales;