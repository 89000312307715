import { Button, FileInput, FileItem, Input } from "../form";
import { useState, useRef } from "react";
import { TextArea } from "../form";
import circle from "../../assets/img/circle.png"
import Api from "../../api";
import { Dialog } from "../design";

const Candidatures = ({ title = "Candidater", jobId }) => {
    const [active, setActive] = useState(false); // Active state for 'upload cv' field
    const [isCvEmpty, setIsCvEmpty] = useState(false);
    const [emptyFiles, setEmptyFiles] = useState(false);
    const [validFiles, setValidFiles] = useState(true)
    const [validCv, setValidCv] = useState(true)
    const [activeFiles, setActiveFiles] = useState(false);// Active state for 'Other docs' field
    const [files, setFiles] = useState([]) //To gather the uploaded files but cv

    const [data, setData] = useState({
        name: "",
        telephone: "",
        mail: "",
        message: "",
        jobid: jobId
    })

    //TO DELETE AN UPLOADED FILE FROM 'files'
    const handleDeleteDocs = (name) => {
        setFiles(files.filter(x => x.name !== name));
        if (files.length === 1) {
            setEmptyFiles(true)
            setValidFiles(true)
        }
    }

    const DisplayDocs = ({ data, handleDelete }) => {
        return (
            <ul>
                {data.map((elem, index) => {
                    return (
                        <FileItem index={index} elem={elem} handleDelete={handleDelete} />
                    )
                })}
            </ul>);
    }

    //USE REF FOR DIALOG
    const dialogContact = useRef(null)
    const dialogcontactText = useRef(null)
    function closeModal() {
        dialogContact.current.close();
    }

    //HANDLE DATA CHANGE
    const handleChange = (event) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value })
    }

    //HANDLE CV FILE
    const [cv, setCv] = useState('');
    const [cvName, setCvName] = useState(null);
    const [cvFile, setCvFile] = useState('');
    const handleCv = (event) => {
        setCv(event.target.value);
        setIsCvEmpty(false)

        verifyExtensionFile(event.target.files[0].name) ? setValidCv(true) : setValidCv(false)
        if (event.target.files.length === 0) {
            setActive(false)
            return
        }
        const fileName = event.target.files[0].name;
        setCvFile(event.target.files[0]);
        //for showing cv name
        setCvName(fileName)
        setActive(true);
    }

    //HANDLE OTHER FILES CHANGE
    const handleFileChange = (event) => {
        [...event.target.files].forEach(
            (submitedFile) => {
                // TO CHANGE BORDER s COLOR
                setEmptyFiles(false)
                if (submitedFile.name !== '') {
                    setActiveFiles(true);
                }
                else {
                    setActiveFiles(false);
                }
                verifyExtensionFile(submitedFile.name) ? setValidFiles(true) : setValidFiles(false)
                let filesCopy = [...files]
                filesCopy.push(submitedFile)
                setFiles(filesCopy)
            }
        );
    }

    function verifyExtensionFile(name) {
        if (name) {
            var extension = name.slice(-3).toLowerCase()
            switch (extension) {
                case "pdf":
                    return true
                case "jpg":
                    return true
                case "png":
                    return true
                default:
                    return false
            }
        }
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        for (var i = 0; i < files.length; i++) {
            if (files[i].name.length > 0 && !verifyExtensionFile(files[i].name)) {
                setValidFiles(false)
                return
            }
        }
        if (files.length !== 0 && !verifyExtensionFile(cvName)) {
            setValidCv(false)
        }
        if (cvFile.length === 0) {
            setIsCvEmpty(true)
        }
        if (files.length === 0) {
            setEmptyFiles(true)
        }

        if (files.length !== 0 && cvFile.length !== 0) {
            Api.post("/apply", data).then(response => {
                if (response.status === 200) {
                    //TO SEND FILES AND CV TO Odoo 
                    Api.filesending(`/apply-files/${response.data.id}`, files, cvFile).then(response => {
                        if (response.status === 200) {
                            dialogcontactText.current.innerHTML = "Candidature envoyé avec succès !"
                            dialogContact.current.showModal()
                        }
                        else {
                            dialogcontactText.current.innerHTML = "Echec de l'opération"
                            dialogContact.current.showModal()
                        }
                    })
                }
                else {
                    dialogcontactText.current.innerHTML = "Echec de l'opération"
                    dialogContact.current.showModal()
                }
            });
        }
    }
    return (

        <div className="py-5 position-relative" style={{ borderRadius: "50px", backgroundColor: "#D2D4F9" }}>
            <div className="row" id="candidature">
                <h2 className="px-5" style={{ color: "#2a2e61" }}>{title}</h2>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <form action="" method="post" onSubmit={handleSubmit} className="applicationForm">
                        <div className="row justify-content-center">
                            <div className="col-lg-5 col-10">
                                <Input type="text" name="lastname" value={data.lastname} label="Nom*" onChange={handleChange} required="*champ obligatoire" />
                            </div>
                            <div className="col-lg-5 col-10">
                                <Input type="text" name="name" value={data.name} label="Prénom*" onChange={handleChange} required="*champ obligatoire" />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-5 col-10">
                                <Input type="tel" name="telephone" pattern="[0-9]{10,}" required={`${data.telephone.length !== 0 ? "Veuillez saisir un numero valide " : "*champ obligatoire"}`} value={data.telephone} label="Téléphone*" onChange={handleChange} />
                            </div>
                            <div className="col-lg-5 col-10">
                                <Input type="email" name="mail" value={data.mail} label="Mail*" onChange={handleChange} required={`${data.mail.length !== 0 ? "Veuillez enter un mail valide" : "*champ obligatoire"}`} />
                            </div>
                        </div>
                        <div className="row mt-2 justify-content-center">
                            <div className="col-10">
                                <FileInput type="file" accept=".png,.jpg,.pdf" id="file-cv" active={active} empty={isCvEmpty} valid={validCv} name="files" value={cv} label="Téléchargez votre CV*" fileName={cvName} onChange={handleCv} required={false} requiredText="*champ obligatoire" verificationText="*Mauvaise extension" />
                            </div>
                        </div>

                        <div className="row mt-4 justify-content-center">
                            <div className="col-10">
                                <FileInput type="file" accept=".png,.jpg,.pdf" id="file-docs" empty={emptyFiles} valid={validFiles} displayFiles={<DisplayDocs data={files.map((x) => x.name)} handleDelete={handleDeleteDocs} />} ismultiple={true} active={activeFiles} requiredText="*champ obligatoire" name="files" verificationText="*Mauvaise extension" label="Autres Documents*" onChange={handleFileChange} required={false} />
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-10">
                                <TextArea rows="5" value={data.message} label="Informations complémentaires" name="message" onChange={handleChange} required={false} ></TextArea>
                                <img src={circle} style={{ position: "absolute", left: "20px", bottom: "10px", zIndex: "-1" }} alt="Circle" />
                            </div>

                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-8">
                                <Button styleType="orange-ctr px-5 ms-4" label="Envoyer" type="submit"></Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Dialog dialogContact={dialogContact} dialogcontactText={dialogcontactText} closeModal={closeModal} />
        </div>
    );
}

export default Candidatures;