import { createContext, useState } from "react";

export const MyContext = createContext(null);

const AppContext = ({ children }) => {
  const [store, setStore] = useState({ cursor: false, dark: window.matchMedia('(prefers-color-scheme: dark)').matches, scroll: 0 })

  return (
    <MyContext.Provider value={[store, setStore]}>
      {children}
    </MyContext.Provider>
  );
}

export default AppContext;