const Pole = ({ img, title }) => {
    return (
        <div className="col-lg-11 col-12 mt-3 ">
            <div style={{
                backgroundImage: `url(${img})`,

            }}
                className="position-relative mx-4 mx-lg-0 pole d-flex align-items-center justify-content-center">
                <p className="m-0 pole-title px-4 text-center">
                    {title}
                </p>
            </div>
        </div>);
}

export default Pole;