import DevImg from "../../assets/img/equipe-web.png"
import graphistImg from "../../assets/img/equipe-graphiste.png"
import pedagogique from "../../assets/img/equipe-pedagogique.png"
import mobile from "../../assets/img/equipe-mobile.png"
import marketing from "../../assets/img/equipe-marketing.png"
import Blur_bleu from "../../assets/img/blur-bleu.png";
import orange from "../../assets/img/blur-orange.png";
import eclipse from "../../assets/img/ellipse.png";
import rectangle from "../../assets/img/rectangle.png";
import triangle from "../../assets/img/triangle.png";

import { useState, useEffect } from "react";
import Api from "../../api";
import Pole from "./Pole";
import Membre from "./Membre";

const Team = () => {
    const [employees, setEmployees] = useState([]);
    const [departments, setdepartments] = useState([]);
    const [departmentWithEmployees, setDepartmentWithEmployees] = useState({});


    useEffect(() => {
        Api.get("/departments").then(response_2 => {
            if (response_2.status === 200) {
                setdepartments(response_2.data.departments);
            }
        })
        Api.get("/employees").then(response => {
            if (response.status === 200) {
                setEmployees(response.data.employees)
            }
        });
    }, [])

    useEffect(() => {
        const employeByDivision = () => {
            const rv = {}
            departments.forEach((department) => {
                rv[department.name] = employees.filter((x) => x.department_id === department.id)
            })
            return rv;
        }
        setDepartmentWithEmployees(employeByDivision())
    }, [employees, departments])

    return (<>
        {/* GRAPHISTE */}
        {Object.keys(departmentWithEmployees).map((k, index) => {
            if (departmentWithEmployees[k].length !== 0 && k === "Graphiste") {
                return (
                    <div className="row justify-content-center" key={index}>
                        <div key={index}>
                            <div className="position-relative" style={{ zIndex: "2" }} key={index}>
                                <Pole img={graphistImg} title={k} />
                                <img className="blur-bleu-equipe blur d-lg-block d-none" height="500px" src={Blur_bleu} alt="Blur décoratif" style={{ zIndex: "-1", pointerEvents: "none" }} />
                                <img className="d-lg-block d-none" height="80%" src={triangle} alt="Blur décoratif" style={{ zIndex: "-1", pointerEvents: "none", position: "absolute", bottom: "-400px", right: "-40px", rotate: "20deg" }} />
                            </div>
                            <div className="row ms-lg-4 negative-margin">
                                {departmentWithEmployees[k].map((elem, elemindex) =>
                                    <Membre favMoment={elem.favoriteMoment} nom={elem.name} poste={elem.job} favPhrase={elem.favoriteSentence} favStuff={elem.like} key={elemindex} img={elem.avatar} />
                                )}
                            </div>
                        </div>
                    </div>
                )
            } else {
                return null
            }

        })}

        {/* WEB */}
        {Object.keys(departmentWithEmployees).map((k, index) => {
            if (departmentWithEmployees[k].length !== 0 && k === "Développement web") {
                return (
                    <div className="row justify-content-center" key={index}>
                        <div key={index}>
                            <div className="position-relative" style={{ zIndex: "2" }} key={index}>
                                <Pole img={DevImg} title={k} />
                                <img className="blur-orange-equipe blur d-lg-block d-none" height="900px" src={orange} alt="Blur décoratif" style={{ zIndex: "-1", pointerEvents: "none" }} />
                                <img className="d-lg-block d-none" height="100%" src={rectangle} alt="Blur décoratif" style={{ zIndex: "-1", pointerEvents: "none", position: "absolute", bottom: "-600px", left: "-120px" }} />

                            </div>
                            <div className="row negative-margin ms-lg-4">
                                {departmentWithEmployees[k].map((elem, elemindex) =>
                                    <Membre favMoment={elem.favoriteMoment} nom={elem.name} poste={elem.job} favPhrase={elem.favoriteSentence} favStuff={elem.like} key={elemindex} img={elem.avatar} />
                                )}
                            </div>
                        </div>
                    </div>
                )
            } else {
                return null
            }

        })}
        {/* MOBILE */}
        {Object.keys(departmentWithEmployees).map((k, index) => {
            if (departmentWithEmployees[k].length !== 0 && k === "Développement mobile") {
                return (
                    <div className="row justify-content-center" key={index}>
                        <div key={index}>
                            <div className="position-relative" style={{ zIndex: "2" }} key={index}>
                                <Pole img={mobile} title={k} />
                                <img className="blur-bleu-equipe blur d-lg-block d-none" height="500px" src={Blur_bleu} alt="Blur décoratif" style={{ zIndex: "-1", pointerEvents: "none" }} />

                            </div>
                            <div className="row negative-margin ms-lg-4">
                                {departmentWithEmployees[k].map((elem, elemindex) =>
                                    <Membre favMoment={elem.favoriteMoment} nom={elem.name} poste={elem.job} favPhrase={elem.favoriteSentence} favStuff={elem.like} key={elemindex} img={elem.avatar} />
                                )}
                            </div>
                        </div>
                    </div>
                )
            } else {
                return null
            }

        })}


        {/* MARKETING */}
        {Object.keys(departmentWithEmployees).map((k, index) => {
            if (departmentWithEmployees[k].length !== 0 && k === "Marketing") {
                return (
                    <div className="row justify-content-center" key={index}>
                        <div key={index}>
                            <div className="position-relative" style={{ zIndex: "2" }} key={index}>
                                <Pole img={marketing} title={k} />
                                <img className="blur-orange-equipe blur d-lg-block d-none" height="900px" src={orange} alt="Blur décoratif" style={{ zIndex: "-1", pointerEvents: "none" }} />
                            </div>
                            <div className="row negative-margin ms-lg-4">
                                {departmentWithEmployees[k].map((elem, elemindex) =>
                                    <Membre favMoment={elem.favoriteMoment} nom={elem.name} poste={elem.job} favPhrase={elem.favoriteSentence} favStuff={elem.like} key={elemindex} img={elem.avatar} />
                                )}
                            </div>
                        </div>
                    </div>
                )
            } else {
                return null
            }

        })}

        {/* PEDAGOGIQUE */}
        {Object.keys(departmentWithEmployees).map((k, index) => {
            if (departmentWithEmployees[k].length !== 0 && k === "Pédagogique") {
                return (
                    <div className="row justify-content-center" key={index}>
                        <div key={index}>
                            <div className="position-relative" style={{ zIndex: "2" }} key={index}>
                                <Pole img={pedagogique} title={k} />
                                <img className="blur-bleu-equipe blur d-lg-block d-none" height="500px" src={Blur_bleu} alt="Blur décoratif" style={{ zIndex: "-1", pointerEvents: "none" }} />
                                <img className="d-lg-block d-none" height="80%" src={eclipse} alt="Blur décoratif" style={{ zIndex: "-1", pointerEvents: "none", position: "absolute", bottom: "-450px", right: "-90px", rotate: "20deg" }} />
                            </div>
                            <div className="row negative-margin ms-lg-4">
                                {departmentWithEmployees[k].map((elem, elemindex) =>
                                    <Membre favMoment={elem.favoriteMoment} nom={elem.name} poste={elem.job} favPhrase={elem.favoriteSentence} favStuff={elem.like} key={elemindex} img={elem.avatar} />
                                )}
                            </div>
                        </div>
                    </div>
                )
            } else {
                return null
            }

        })}

    </>);
}

export default Team;