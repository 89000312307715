import { Button } from "../form";
// import Conducteur from "../../assets/img/conducteur.png";
import Groupe_42 from "../../assets/img/groupe_42.png"
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils";

const Section1 = () => {
    let navigate = useNavigate();

    function buttonClick() {
        navigate(ROUTES.NOSPROJETS);
    }
    return (
        <div className="px-0">
            <div className="row header">
                <div className="col-12 col-lg-6 paragraph pl-xl-5 order-2 order-lg-1">
                    <h1 className="title-underline title-1">Educacode</h1>
                    <h2 className="subtitle text-white ">La nouvelle ère <br /> de la formation digitale</h2>
                    <p className="paragraphe-home">Rendre l'éducation plus efficace et pertinente grâce à la technologie, c'est l'objectif d'Educacode. Jeune et pleine d'ambition, l'entreprise se consacre à la digitalisation des formations pour les rendre accessibles à tous et dans l'air du temps.</p>
                    <Button type="button" label="Découvrir nos projets" styleType='orange px-5 mt-5 py-2' onClick={buttonClick}></Button>
                </div>
                <div className="col-12 col-lg-6 text-right pr-0 order-1 order-lg-2 d-flex justify-content-end">
                    <div className="image-wrapper">
                        <img src={Groupe_42} alt="Conducteur en voiture" className="img-100 image-home-1" />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Section1;