import { PageTitle, Container } from "../components/base"
import AsidePage from "../components/base/AsidePage";
import { Section1 } from "../components/mentionsLegeles";
const MentionsLegales = () => {
    return (
        <>
            <Container>
                <div className="row" style={{ margin: 20 }}>
                    <PageTitle title="mentions" subTitle="légales" hideTitle={true} />
                </div>
                <AsidePage mobileFull={true} title="" fixed_title_bottom="fixed-title-nousrejoindre" pageComponent={<Section1 />} scroll={0.6} />
            </Container>
        </>);
}

export default MentionsLegales;