
import { useContext, useEffect, useState, useRef } from "react";
import { MyContext } from "../../context/AppContext";

const Mouse = () => {
    const cursor = useRef(null);
    const [store] = useContext(MyContext);
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        const handleMouseMove = (event) => {
            setVisible(true);
            cursor.current.style.transform = `translate(${event.clientX + cursor.current.clientWidth * 0.3}px  , ${event.clientY + cursor.current.clientHeight * 0.3}px)`;
        };
        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    },
        []
    );
    return (
        <div className={`cursor ${visible ? '' : 'd-none'} `} ref={cursor}>
            <div className={`content ${store.cursor ? 'active' : ''} `}></div>
        </div>);
}

export default Mouse;