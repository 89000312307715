import { Input, TextArea } from "../components/form";
import { useRef, useState } from "react";
import Button from "../components/form/Button";
import Triangle from "../assets/img/triangle.png";
import Api from "../api.js"
import { Container } from "../components/base";
import { Dialog } from "../components/design";

const Contact = () => {
    const [formData, setFormData] = useState({
        name: "",
        lastname: "",
        objet: "",
        mail: "",
        message: "",
    });

    const dialogContact = useRef(null)
    const dialogcontactText = useRef(null)

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleSubmit = (event) => {
        Api.post("/contact", formData).then(response => {
            if (response.status === 200) {
                dialogcontactText.current.innerHTML = "Message envoyé avec succès !"
                dialogContact.current.showModal()
            }
            else {
                dialogcontactText.current.innerHTML = "Echec de l'opération"
                dialogContact.current.showModal()
            }
        });
        event.preventDefault();
    }

    function closeModal() {
        dialogContact.current.close();
    }
    return (
        <Container>
            <div className="row d-flex pt-5 justify-content-center position-relative" style={{ zIndex: "2", marginBottom: "150px" }} >
                <div className="col-12 col-lg-6 mb-auto">
                    <h1 className="title mb-5 px-lg-5">Contact</h1>
                    <div className="position-relative"><div className="radius bg-primary overflow-hidden infos position-relative">
                        <h2 className="mb-4 ">Nous contacter</h2>
                        <p className="pb-3">
                            Vous avez une question, une suggestion ou besoin d’une assistance&#160;? L’équipe d'Educacode est là pour vous aider&#160;!
                            Contactez-nous en remplissant le formulaire, nous vous répondrons dans les plus brefs délais.
                        </p>
                        <div className="position-relative">
                            <p className="picto-contact picto-contact-adresse mx-4">Educacode <br />
                                9 Rue du Cerf-Volant <br />
                                17000 La Rochelle</p>
                            <p className="picto-contact picto-contact-tel mx-4">+33 2 51 50 07 72</p>
                        </div>
                        <img src={Triangle} className="triangle" alt="" />
                    </div>
                        <div className=" spreading bg-primary"></div>
                    </div>


                </div>
                <div className="col-12 col-lg-6 form mt-5 contact-form-mobile">
                    <div className="mx-lg-5 mx-4 form-contact">
                        <h3 className="fw-bolder mt-4 mb-4 contact-form-title">Ecrivez-nous</h3>
                        <form action="" method="post" onSubmit={handleSubmit}>
                            <Input type="text" name="lastname" value={formData.lastname} label="Nom" onChange={handleChange} required="*champ obligatoire" />
                            <Input type="text" name="name" value={formData.name} label="Prénom" onChange={handleChange} required="*champ obligatoire" />
                            <Input type="email" name="mail" value={formData.mail} label="E-mail" onChange={handleChange} required={`${formData.mail !== '' ? "Veuillez enter un mail valide" : "*champ obligatoire"}`} />
                            <Input type="text" name="objet" value={formData.objet} label="Objet" onChange={handleChange} required="*champ obligatoire" />
                            <TextArea rows="5" label="Message" name="message" value={formData.message} onChange={handleChange} required="*champ obligatoire" ></TextArea>
                            <Button type="submit" styleType="orange-ctr py-1 px-5" label="Envoyer" />
                        </form>
                    </div>
                </div>
            </div>
            <Dialog dialogContact={dialogContact} dialogcontactText={dialogcontactText} closeModal={closeModal} />
        </Container>

    );
}

export default Contact;