import { Link } from "../base";
import { ROUTES } from "../../utils";

import cercle1 from "../../assets/img/error/cercle-1.svg"
import cercle2 from "../../assets/img/error/cercle-2.svg"
import carre1 from "../../assets/img/error/carre-1.svg"
import carre2 from "../../assets/img/error/carre-2.svg"
import triangle1 from "../../assets/img/error/triangle-1.svg"
import triangle2 from "../../assets/img/error/triangle-2.svg"


const Section1 = () => {
    return (
        <>
            <div className="Notfound">
                <div className="forme_error forme_error-rond p-2">
                    <img className="forme_error-rond-1" src={cercle1} alt="" />
                    <img className="forme_error-rond-2" src={cercle2} alt="" />
                </div>
                <div className="d-flex justify-content-center">
                    <h2 className="subtitle" >La page que vous cherchez semble introuvable !</h2>
                </div>
                <div className="row">
                    <h3 className="subtitle pt-5 pb-3">Vous cherchiez peut-être l'une des pages suivantes</h3>
                    <div className="col-lg-4 col-6 link-notFound">
                        <Link to={ROUTES.NOSPROJETS} className="link-notFound">Nos projets</Link>
                        <br />
                        <Link to={ROUTES.NOSMETIERS} className="link-notFound">Nos métiers</Link>
                        <br />
                        {/* <Link to={ROUTES.EQUIPE} className="link-notFound">L'équipe</Link> */}
                        <br />
                        <Link to={ROUTES.NOUSREJOINDRE} className="link-notFound">Nous rejoindre</Link>
                        <br />
                        <Link to={ROUTES.NOSPARTENAIRES} className="link-notFound">Nos partenaires</Link>
                        <br />
                        <Link to={ROUTES.CONTACT} className="link-notFound">Nous contacter</Link>
                    </div>
                    <div className="forme_error forme_error-triangle col-6">
                        <img className="forme_error-triangle-1" src={triangle1} alt="" />
                        <img className="forme_error-triangle-2" src={triangle2} alt="" />
                    </div>

                </div>
                <div className="forme_error forme_error-carre">
                    <img className="forme_error-carre-1" src={carre1} alt="" />
                    <img className="forme_error-carre-2" src={carre2} alt="" />
                </div>

            </div>
        </>
    );
}

export default Section1;