import { Button } from "../form";
import { Box } from "../base";
import Reunion from "../../assets/img/reunion.png";
import Reunion2 from "../../assets/img/reunion2.png";
import Trianglorange from "../../assets/img/triangle.png"
import Ligne from "../../assets/img/Ligne 6.png"
import Blur_bleu from "../../assets/img/blur-bleu.png";
import Ellipse from "../../assets/img/ellipse.png";
import Rectangle from "../../assets/img/rectangle.png";
import OwlCarousel from 'react-owl-carousel';
import { useEffect, useState } from "react";
import Api from "../../api";
import JobDetail from "./JobDetail";
import { Candidature } from ".";

const Section1 = () => {

    const [application, setApplication] = useState(false);
    const handleApplication = () => {
        setApplication((prev) => !prev)
    }
    const [jobs, setJobs] = useState([]);
    useEffect(() => {
        Api.get("/jobs").then(response => {
            if (response.status === 200) {
                setJobs(response.data.jobs)
            }
        });
    }, [])

    let data = [
        {
            text: "Car l'entreprise évolue rapidement, elle doit s'adapter en permanence aux nouvelles technologies et aux tendances du marché",
            title: " L'adaptabilité",
            imgStyle: " d-none",
            titleStyle: "font-weight-bolder pb-2",
            pStyle: "nousRejoindre-carroussel-style"
        },
        {
            text: "On encourage les employés à apprendre constamment pour répondre à la demande et rester à la pointe de l'innovation",
            title: "La curiosité",
            imgStyle: " d-none",
            titleStyle: "font-weight-bolder pb-2",
            pStyle: "nousRejoindre-carroussel-style"
        },
        {
            text: "Nous croyons que le travail en équipe est la clé pour atteindre nos objectifs et réussir collectivement",
            title: "Le sens du collectif",
            imgStyle: " d-none",
            titleStyle: "font-weight-bolder pb-2 ",
            pStyle: "nousRejoindre-carroussel-style"
        }
    ];
    return (
        <>
            <div className="row mt-lg-5 justify-content-lg-between justify-content-center">
                <div className="col-lg-5 col-10">
                    <div className=" nousRejoindre-subtitle">

                        <h1>Rejoignez <span>Educacode</span></h1>
                        <p style={{ margin: 0, paddingTop: 30 }}>
                            <span>Rejoindre Educacode</span>, c'est intégrer une équipe jeune et ambitieuse, animée par une passion commune pour l'innovation. Dans un environnement de travail <span>stimulant</span> et <span>convivial</span>, nous offrons des projets variés et passionnants construits autour de la créativité et de l'éducation.
                        </p>
                        <p style={{ margin: 0, paddingTop: 10, zIndex: 5 }}>
                            Si vous êtes prêt(e) à relever <span>des défis ambitieux</span>, à contribuer à des projets importants et à évoluer au sein d'une entreprise en pleine croissance, <span className="nousRejoindre-underline position-relative" style={{ width: 'max-content', zIndex: 2 }}>
                                rejoignez-nous&#160;!
                            </span>
                        </p>
                        <Button type="button" label="Nos offres d'emploi" styleType='orange-ctr px-5 mt-5 py-2 btn-lg mb-lg-0 mb-5'></Button>
                    </div>
                </div>
                <div className="col-lg-6 col-10 m-lg-0  row justify-content-center position-relative" style={{ paddingRight: 24, zIndex: 5 }}>
                    <div className="position-relative w-auto h-auto pl-5" style={{ zIndex: 5, }} >
                        <img src={Reunion} alt="Réunon" className="nousRejoindre-reunion1" />
                        <div className="nousRejoindre-triangle-orange">
                            <img src={Trianglorange} alt="" className="" />
                        </div>
                    </div>
                    <img src={Ellipse} className="nousRejoindre-ellipse-orange" alt="Ellipse" />
                </div>
            </div>
            <div className="row">
                <div className="row position-relative h-100 nous-rejoindre-section2" style={{ marginTop: 160, zIndex: 9 }}>
                    <img src={Reunion2} alt="Réunon" className="nousRejoindre-Reunion2" />
                    <img src={Blur_bleu} className="nousRejoindre-blur-bleu" alt="Blue blur" />
                </div>
                <div className="row h1-style-nousRejoindre" style={{ marginTop: 150 }}>
                    <div className="col-4">
                        <div className="d-flex">
                            <div className="d-block">
                                <h1> L'adaptabilité </h1>
                                <p className="pt-3" style={{ fontSize: "16px" }}>Car l'entreprise évolue rapidement, elle doit s'adapter en permanence aux nouvelles technologies et aux tendances du marché</p> </div>
                            <div className="mx-5">
                                <img src={Ligne} alt="Ligne" />
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="d-flex">
                            <div className="d-block">
                                <h1> La curiosité </h1>
                                <p className="pt-3" style={{ fontSize: "16px" }}>On encourage les employés à apprendre constamment pour répondre à la demande et rester à la pointe de l'innovation</p> </div>
                            <div className="mx-5">
                                <img src={Ligne} alt="Ligne" />
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <h1> Le sens du collectif </h1>
                        <p className="pt-3" style={{ fontSize: "16px" }}>Nous croyons que le travail en équipe est la clé pour atteindre nos objectifs et réussir collectivement</p>
                    </div>
                </div>
            </div >


            <div className="nousRejoindre-carroussel-mobile w-70 d-none" style={{ marginTop: "100px" }} >
                <OwlCarousel stageClass="d-flex" items={3} className="owl-theme " nav margin={30} responsive={{
                    0: {
                        items: 1
                    },
                }} >
                    {data.map((elem, index) => <Box titleStyle={elem.titleStyle} text={elem.text} title={elem.title} imgStyle={elem.imgStyle} key={index} className="col-12  card-invisible border-0 text-center" pStyle={elem.pStyle} cardstyle={"card-mobile-nousRejoindre"} />
                    )}
                </OwlCarousel>
            </div >

            <div className="row justify-content-center my-5">
                <div className="col-10 position-relative" style={{ zIndex: 2 }} >
                    <img src={Rectangle} alt="" className="nousRejoindre-rectangle" />
                    <div className="w-100 text-center">
                        <h1 className="offre-h1">Toutes nos offres d'emploi</h1>
                    </div>
                    <div className="w-100">
                        {jobs.map((elem, index) => <JobDetail {...elem} key={index} />)}

                        <div className="my-5 px-4 py-4" style={{ backgroundColor: "rgba(210, 212, 249, 0.5)", borderRadius: "30px" }}>
                            <div className="d-lg-flex d-block align-items-center justify-content-between  ">
                                <h3 className="card-titre">Envoyer une candidature spontanée</h3>
                                <Button type="button" styleType="blue-button" label="Postuler" onClick={handleApplication} />
                            </div>
                        </div>
                        <div className={`${application ? " " : "d-none"}`}>
                            <Candidature title="Candidature spontanée" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-2"></div>
            </div>
        </>
    );
}

export default Section1;