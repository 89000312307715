import { Container, PageTitle, AsidePage } from "../components/base";
import { Section1 } from "../components/notFound";

export default function NotFound() {
  return (
    <>
      <Container>
        <div className="row row-notfound" style={{ margin: 20 }}>
          <PageTitle title="erreur" subTitle="404" hideTitle={true} />
        </div>
        <AsidePage title="OUPS !" pageComponent={<Section1 />} styleType={"notfound-side-title"} />
      </Container>
    </>
  );
}