import Blur_bleu from "../../assets/img/blur-bleu.png";
import Dev from "../../assets/img/dev.png";
import Ellipse from "../../assets/img/ellipse.png";
import Triangle from "../../assets/img/triangle.png";
import Ligne from "../../assets/img/ligne.png";
import { Button } from "../form";
import { useContext } from "react";
import { MyContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils";

const Section2 = () => {
    const [store] = useContext(MyContext)
    let navigate = useNavigate()
    function buttonClick() {
        navigate(ROUTES.NOUSREJOINDRE);
    }
    return (
        <div className="container-fluid px-0 mt-5">
            <div className="row justify-content-center align-items-center presentation p-4 px-5">
                <div className="col-12 col-lg-5 text-center position-relative" style={{ zIndex: "2" }}>
                    <img src={Dev} className="presentation__img img-100" alt="Blur décoratif" />
                    <img className="blur-bleu blur" src={Blur_bleu} alt="Blur décoratif" style={{ zIndex: "-1", pointerEvents: "none" }} />
                    <img src={Ellipse} className="forme-rond" alt="" style={{ transform: `rotate(-${store.scroll * 2}rad)`, transformOrigin: "top", zIndex: -1 }} />
                    <img src={Ligne} className="forme-ligne" alt="" style={{ transform: `rotate(${store.scroll * 2}rad)`, transformOrigin: "left", zIndex: -1 }} />
                    <img src={Triangle} className="forme-triangle" alt="" style={{ transform: `rotate(-${store.scroll * 5}rad)`, zIndex: -1 }} />
                </div>
                <div className="col-12 col-lg-5 mx-5 mx-lg-0 my-5  my-lg-0">
                    <h2 className="subtitle">Des équipes <span className="text-orange" style={{ wordBreak: "break-word" }}>pluridisciplinaires</span> pour des
                        projets variés</h2>
                    <p className="mt-5"> De la conception à la réalisation, nos équipes s'investissent à chaque étape du processus pour garantir la qualité de nos formations. Nous sommes en quête constante de talents pour intégrer nos équipes et participer à la mise en place de nos idées. Rejoignez-nous et soyez au cœur de l'innovation !</p>
                    <Button type="button" styleType="orange px-5 mt-5" label="Découvrir nos offres d'emploi" onClick={buttonClick}></Button>
                </div>
            </div>
        </div>
    );

}

export default Section2;