import { Team } from "../components/equipe";
import { PageTitle, Container } from "../components/base"
import AsidePage from "../components/base/AsidePage";
const Equipe = () => {
    return (
        <Container>
            <div className="row" style={{ margin: 20 }}>
                <PageTitle title="L'ÉQUIPE" subTitle="de choc" />
            </div>

            <AsidePage mobileFull={true} title="NOTRE ÉQUIPE" pageComponent={<Team />} />
        </Container>
    );
}

export default Equipe;