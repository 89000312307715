import { PageTitle, Container } from "../components/base"
import AsidePage from "../components/base/AsidePage";
import { Section1 } from "../components/nousRejoindre"
const NousRejoindre = () => {
    return (
        <>
            <Container>
                <div className="row" style={{ margin: 20 }}>
                    <PageTitle title="NOUS REJOINDRE" subTitle="recrutement" hideTitle={true} />
                </div>
                <AsidePage mobileFull={true} title="NOUS REJOINDRE" fixed_title_bottom="fixed-title-nousrejoindre" pageComponent={<Section1 />} scroll={0.6} />
            </Container>
        </>
    );
}

export default NousRejoindre;