import Codapagos from "../../assets/img/codapagos.png"
import SiteCodapagos from "../../assets/img/siteCodapagos.png"
import AppCodapro from "../../assets/img/appCodapros.png"
import AppCodapagos from "../../assets/img/appCodapagos.png"
import Triangle from "../../assets/img/polygone.png"
import Rectangle from "../../assets/img/rectangle64.png"
import Circle from "../../assets/img/ellipse7.png"

const Projects = () => {

    return (
        <div className="nosProjet">
            <div className="row px-3 px-lg-0 mt-5 d-flex">


                <div className="col-lg-6 col-12 order-lg-1 order-2 ">
                    <h3 className="subtitle mt-5 mt-lg-5 pb-3">Codapagos</h3>
                    <p>Le projet Codapagos, en association avec ActiROUTE, est une solution d'enseignement du code de la route pour les jeunes futurs conducteurs.
                        Hybride, son approche pédagogique innovante combine des leçons sur une <a href="#codapagos" >application</a>  mobile suivies d'un stage en établissement scolaire animé par un enseignant de la conduite.</p>
                </div>
                <div className="col-lg-4 col-12 order-lg-2 order-1 position-relative " style={{ zIndex: "2" }} >
                    <div className="" style={{
                        filter: "blur(20px)", width: "100%", height: "100%", position: "absolute", top: "0px", zIndex: "-2", backgroundColor: "transparent", borderRadius: "80px", backdropFilter: "blur(10px)"
                    }}>
                    </div>
                    <img src={Codapagos} style={{ zIndex: "1" }} className="img-100" alt="Codapagos" />
                    <img src={Triangle} style={{ zIndex: "-3", right: "-40px", bottom: "-50px", position: "absolute", height: "60%" }} alt="Triangle" />

                </div>
            </div>

            <div className="row px-3 px-lg-0 mt-5" >
                <div className="col-lg-6 col-12 order-lg-1 order-2">
                    <h3 className="subtitle mt-5 pb-3" id="codapagos">L'application Codapagos</h3>
                    <p>Codapagos est également une application mobile qui permet aux élèves d'apprendre et de s'entrainer au code de la route à tout moment et n'importe où. Au travers de vidéos ludiques, de quiz et de tests pratiques, les élèves peuvent s'imprégner des éléments clés avant leur semaine en présentiel.</p>
                </div>
                <div className="col-lg-4 col-12 order-lg-2 order-1 position-relative" style={{ zIndex: "2" }}>
                    <div className="" style={{
                        filter: "blur(20px)", width: "100%", height: "100%", position: "absolute", top: "0px", zIndex: "-2", backgroundColor: "transparent", borderRadius: "80px", backdropFilter: "blur(10px)"
                    }}>
                    </div>
                    <img src={AppCodapagos} className="img-100" style={{ zIndex: "1" }} alt="Application Codapagos" />
                    <img src={Rectangle} style={{ zIndex: "-3", rotate: "0deg", right: "-10px", bottom: "-50px", position: "absolute", height: "60%" }} alt="Rectangle" />

                </div>
            </div>
            <div className="row px-3 px-lg-0 mt-5" >
                <div className="col-lg-6 col-12 order-lg-1 order-2">
                    <h3 className="subtitle mt-lg-0 mt-5 pb-3">L'application Codapro</h3>
                    <p>Cette application est l'outil indispensable des enseignants durant la semaine d'apprentissage en présentiel.
                        Grâce à elle, ils peuvent assurer le suivi administratif du stage et contrôler la <a href="#codabox">Codabox</a> en lançant les quiz et diaporamas de cours.
                        De plus, l'accès anticipé à l'ensemble des supports leur permet de préparer l'enseignement des élèves dont ils peuvent suivre la progression sur Codapagos.</p>
                </div>
                <div className="col-lg-4 col-12 order-lg-2 order-1 position-relative" style={{ zIndex: "2" }}>
                    <div className="" style={{
                        filter: "blur(20px)", width: "100%", height: "100%", position: "absolute", top: "0px", zIndex: "-2", backgroundColor: "transparent", borderRadius: "80px", backdropFilter: "blur(10px)"
                    }}></div>
                    <img src={AppCodapro} className="img-100" style={{ zIndex: "1" }} alt="Application Codapro" />
                    <img src={Circle} style={{ zIndex: "-3", left: "-16px", bottom: "-50px", position: "absolute", height: "50%" }} alt="Circle" />

                </div>
            </div>


            <div className="row px-3 px-lg-0 mt-5 ">
                <div className="col-lg-6 col-12 order-lg-1 order-2">
                    <h3 className="subtitle">Le site Codapagos.com</h3>
                    <p>Ce site est également un ERP basé sur le produit Open Source Odoo et conçu pour faciliter l'organisation des stages. Cet outil permet de centraliser toutes les informations nécessaires au service, offrant ainsi une vue globale de l'activité  en temps réel. Grâce à lui, il devient facile de gérer et de suivre tous les aspects du service, ce qui permet une prise de décision rapide et éclairée pour une gestion optimale des stages.</p>
                </div>
                <div className="col-lg-4 col-12 pb-5 order-lg-2 order-1">
                    <img src={SiteCodapagos} className="img-100" alt="Site web Codapagos" />

                </div>
            </div>
            <div className="row px-3 px-lg-0 " >
                <div className="col-lg-12 col-12">
                    <h3 className="subtitle pb-3">Les serveurs LMS </h3>
                    <h4 className="fw-bold" >(serveurs de suivi pédagogique des stagiaires)</h4>
                    <p>Cet outil pédagogique développé par Educacode collecte et rassemble les informations de progression essentielles des utilisateurs sur les formations proposées. L'analyse de ces informations permet ensuite de proposer une expérience d'apprentissage adaptée. Le LMS simplifie la gestion des formations en permettant un meilleur suivi des performances et une planification plus efficace des activités.</p>
                </div>
                <div className="col-lg-4 col-12"></div>
            </div>
            <div className="row px-3 px-lg-0 mt-5 mb-5" >
                <div className="col-lg-12 col-12">
                    <h3 className="subtitle pb-3" id="codabox">La Codabox</h3>
                    <p>La Codabox est un objet connecté lié à l'application Codapro, utilisée pendant les stages Codapagos en présentiel. Elle génère un Hotspot Wi-Fi qui permet à l'enseignant de la conduite de partager ses cours et les questions d'entraînement avec les élèves. Elle est également connectée en 4G avec les serveurs Educacode pour remonter les suivis administratif et permettre les actions de maintenance et de mise à jour à distance. Ce dispositif permet de traiter les données localement, réduisant considérablement la latence ce qui garantit le bon fonctionnement du service durant les stages. La Codabox est l'outil essentiel pour assurer un apprentissage optimal de la conduite.</p>
                </div>
                <div className="col-lg-4 col-12"></div>
            </div>
        </div>);
}

export default Projects;