import { useEffect, useState } from "react";

const TextArea = ({ name, rows, label, value, onChange, required = 1 }) => {
    const [active, setActive] = useState(false);
    const handleFocus = () => setActive(true);
    const handleBlur = () => value ? setActive(true) : setActive(false);
    const [validity, setValidity] = useState(false)
    const handleInvalid = (e) => {
        e.preventDefault();
        setValidity(true);
    }
    const handleInput = () => {
        setValidity(false);

    }
    useEffect(() => {
        if (value) {
            setActive(true);
        }
    }, [value])
    return (
        <div className="form-group my-3 position-relative">
            <textarea
                rows={rows}
                name={name}
                className={`form-control ${active ? 'active' : ''}`}
                value={value ? value : ""}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={onChange}
                required={required}
                autoComplete="off"
                onInvalid={(e) => handleInvalid(e)}
                onInput={handleInput}
            />
            <span className={`floating-label text-area ${active ? 'active' : ''}`}>{label}</span>
            {validity && <span className="tooltip">{required}</span>}
        </div>
    )
}

export default TextArea;