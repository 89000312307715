import { useRef, useContext } from "react"
import arrow from "../../assets/img/back-top-arrow.svg"
import backtop from "../../assets/img/back-top.svg"
import { MyContext } from "../../context/AppContext"

const ScrollUp = () => {
    const [store] = useContext(MyContext);
    const divScrollUp = useRef(null)
    if (store.scroll !== 0) {
        divScrollUp.current?.classList.remove("no-display")
    }
    else {
        divScrollUp.current?.classList.add("no-display")
    }

    return (
        <>
            <div className="back-top no-display" ref={divScrollUp}>
                <a href="#top-ref" className="back-top-link">
                    <img src={arrow} alt="arrow" className="back-top-arrow" />
                    <img className="back-top-text" src={backtop} alt="back-top" />
                </a>
            </div>
        </>
    );
}

export default ScrollUp;