import Poste from "../components/nousRejoindre/Poste";

const JobOffer = () => {
    return (
        <div className="container">
            <Poste></Poste>
        </div>
    );
}

export default JobOffer;