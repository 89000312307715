import { Container, PageTitle } from "../components/base";
import { Construction } from "../components/construction"
const SiteEnConstruction = () => {
    return (
        <div className="h-100 w-100 overflow-hidden">
            <Container>
                <div className="row row-notfound justify-content-center" style={{ margin: 20 }}>
                    <PageTitle title="SITE en" subTitle="construction" hideTitle={true} />
                    <Construction></Construction>
                </div>
            </Container>
        </div >);
}

export default SiteEnConstruction;