import Ordinateur from "../../assets/img/ordinateur.svg";
import PictoTel from "../../assets/img/picto-tel.svg";
import Crayon from "../../assets/img/crayon.svg";
import BlurOrange from "../../assets/img/blur-orange.png";
import { Box } from "../base";
import OwlCarousel from 'react-owl-carousel';
import { Button } from "../form";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils";
const Section3 = () => {
    let navigate = useNavigate();

    function buttonClick() {
        navigate(ROUTES.EQUIPE);
    }
    return (
        <div className="container-fluid px-0">
            <div className="row text-center mb-5 position-relative px-5" style={{ zIndex: "2" }}>
                <div className="col-12 my-5">
                    <h2 className="subtitle">Nos métiers</h2>
                </div>
                <OwlCarousel stageClass="d-flex" items={3} className="owl-theme" nav margin={30} responsive={{
                    0: {
                        items: 1
                    },
                    1100: {
                        items: 3
                    }
                }} >
                    <div className="h-100 ">
                        <Box title="Développement Web" img={Ordinateur} text="Nos développeurs web allient une approche créative et une maîtrise technique pour concrétiser nos idées en réalité numérique. Leur contribution est essentielle pour rendre nos projets impactants et accessibles partout." className="card overflow-hidden bg-primary p-lg-5 py-5 px-2 h-100" hrStyle="true" imgStyle="top" design="true" hrclass="hr-box-home" />
                    </div>
                    <div className="h-100"><Box title="Développement Mobile" img={PictoTel} text="Grâce à leur rigueur et leur capacité à innover, nos développeurs mobile enrichissent notre offre de formation et permettent de proposer une expérience d'apprentissage mobile de qualité et adaptée à tous." className="card overflow-hidden bg-primary p-lg-5 py-5 px-3  h-100" hrStyle="true" design="true" imgStyle="top" hrclass="hr-box-home" />
                    </div>
                    <div className="h-100"><Box title="Graphisme" img={Crayon} text="En travaillant en étroite collaboration avec les autres pôles de l'entreprise, les graphistes garantissent une cohérence et une harmonie visuelle dans l'ensemble de nos outils pour offrir une expérience utilisateur de qualité." className="card overflow-hidden bg-primary p-lg-5 py-5 px-2 h-100" hrStyle="true" imgStyle="top" design="true" hrclass="hr-box-home" />
                    </div>
                </OwlCarousel>

                <img src={BlurOrange} className="blur-orange blur" alt="" />
                <div className="d-flex justify-content-center my-5 ">

                    <Button type="button" label="Rencontrer les équipes" styleType="orange px-5 mt-4 section3-button" onClick={buttonClick} />
                </div>
            </div>
        </div>


    );
}

export default Section3;