import Axios from "axios";
import history from "./history";

const baseUrl = process.env.REACT_APP_ODOO_URL;

Axios.interceptors.response.use(
	async function (response) {
		if (response.data.csrf) {
			localStorage.setItem("Csrf-Token", response.data.csrf);
		}

		if (response.status === 403 || response.status === 400) {
			Api.get("/csrf");
			if (response.config.method === "post") {
				const resp = await Axios.post(
					response.config.url,
					response.config.data
				);
				return resp;
			}
		}
		if (response.status === 401) {
			history.push("/");
		}
		return response;
	},
	function (error) {
		return Promise.reject(error);
	}
);

export default class Api {
	static async get(url) {
		try {
			const res = await Axios.get(`${baseUrl}${url}`, {
				headers: {
					Accept: "application/json text/html",
				},
				validateStatus: () => true,
				data: null,
			});
			return res;
		} catch (err) {
			return err.response;
		}
	}

	static async post(url, body) {
		try {
			const res = await Axios.post(`${baseUrl}${url}`, body, {
				headers: {
					Accept: "application/json, text/html",
					"Content-Type": "application/json",
					"Csrf-Token": localStorage.getItem("Csrf-Token"),
				},
				validateStatus: () => true,
			});
			return res;
		} catch (err) {
			return err.response;
		}
	}

	static async filesending(url, files, cvFile) {
		try {
			let formData = new FormData()
			formData.append("csrf_token", localStorage.getItem("Csrf-Token"))
			formData.append("cv", cvFile)
			files.forEach((element, index) => {
				formData.append(index, element)
			});
			const res = await Axios.post(`${baseUrl}${url}`, formData, {
				headers: {
					Accept: "application/json, text/html",
					"Content-Type": "multipart/form-data",
					"Csrf-Token": localStorage.getItem("Csrf-Token"),
				},
				validateStatus: () => true,
			});
			return res;
		} catch (err) {
			return err.response;
		}
	}

	static async delete(url) {
		try {
			const res = await Axios.delete(`${baseUrl}${url}`, {
				headers: {
					Accept: "application/json, text/html",
					"Csrf-Token": localStorage.getItem("Csrf-Token"),
				},
				validateStatus: () => true,
			});
			return res;
		} catch (err) {
			return err.response;
		}
	}

	static async patch(url, body) {
		try {
			const res = await Axios.patch(`${baseUrl}${url}`, body, {
				headers: {
					Accept: "application/json, text/html",
					"Content-Type": "application/json",
					"Csrf-Token": localStorage.getItem("Csrf-Token"),
				},
				validateStatus: () => true,
			});
			return res;
		} catch (err) {
			return err.response;
		}
	}

	static async put(url, body) {
		try {
			const res = await Axios.put(`${baseUrl}${url}`, body, {
				headers: {
					Accept: "application/json, text/html",
					"Content-Type": "application/json",
					"Csrf-Token": localStorage.getItem("Csrf-Token"),
				},
				validateStatus: () => true,
			});
			return res;
		} catch (err) {
			return err.response;
		}
	}
}
