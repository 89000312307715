import Croix from "../../assets/img/croix.png";
import Enveloppe from "../../assets/img/enveloppe.png";
const Dialog = ({ dialogContact, dialogcontactText, closeModal }) => {
    return (
        <dialog className="dialog-contact px-5 py-4 position-relative" ref={dialogContact}>
            <div>
                <img src={Croix} alt="croix" onClick={closeModal} style={{ position: "absolute", top: "0px", right: "0px" }} className="pe-3 pt-3" />
                <div className="d-flex align-items-center flex-column py-2"><img src={Enveloppe} alt="enveloppe" className="pb-3" />
                    <p className="dialog-text" ref={dialogcontactText}>

                    </p>
                </div>

            </div>
        </dialog>);
}

export default Dialog;