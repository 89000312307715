import { useEffect, useContext } from "react";
import { MyContext } from "../../context/AppContext";
const Scroll = () => {
    const setStore = useContext(MyContext)[1]

    useEffect(() => {
        const rotate = () => {
            setStore((prevStore) => ({ ...prevStore, scroll: document.documentElement.scrollTop / document.documentElement.offsetHeight }));
        }
        window.addEventListener('scroll', rotate);
        return () => {
            window.removeEventListener('scroll', rotate)
        };
    },
        [setStore]
    );
    return (<></>);
}

export default Scroll;