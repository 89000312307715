const FileItem = ({ elem, index, handleDelete }) => {
    return (
        <li key={index}>
            <div className="d-flex align-items-center justify-content-start mt-0 mb-1" >
                <span>{elem}</span>
                <div className="d-flex" onClick={() => handleDelete(elem)}>
                    <button type="button" className="bg-transparent border-0"><span className="ms-4 my-auto text-danger mr-2">Supprimer</span></button>
                </div>
            </div>
        </li>);
}

export default FileItem;