const ROUTES = {
	HOME: "/",
	SITEMAP: "/sitemap.xml",
	NOTFOUND: "*",
	CONTACT: "/contact",
	NOSMETIERS: "/nos-metiers",
	NOUSREJOINDRE: "/nous-rejoindre",
	CONSTRUCTION: "/en-construction",
	NOSPROJETS: "/nos-projets",
	NOSPARTENAIRES: "/nos-partenaires",
	EQUIPE: "/equipe",
	MENTIONSEGALES: "/mentions-legales",
	POLITIQUES: "/politique-de-confidentialite",
	CONDITIONSGENERALES: "/cgu",
	COOKIES: "/cookies",
	JOBOFFER: "/details-offre/:id",
};

export default ROUTES;
