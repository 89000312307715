import { Link as ReactLink } from "react-router-dom";
import { useContext } from "react";
import { MyContext } from "../../context/AppContext";


const Link = (props) => {
    const [store, setStore] = useContext(MyContext)

    const handleMouseEnter = () => {
        setStore({ ...store, cursor: true });
    }
    const handleMouseLeave = () => {
        setStore({ ...store, cursor: false });
    }

    return (
        <>
            {props.external ? <a href={props.href} className={props.className} target={props.target ? props.target : ''} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>{props.label}</a> : <ReactLink {...props} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ></ReactLink>}
        </>
    );
}

export default Link;