import RoundCard from "../../assets/img/rond-card.svg";
import triangleCard from "../../assets/img/triangle-card.svg";
const Box = ({ img, title, text, className, design, hrStyle, titleStyle, imgStyle, hrclass, pStyle, cardstyle }) => {
    return (


        <div className={`  ${className}`}>
            {design && <>
                <img src={RoundCard} className="card__forme card__forme--rond" alt="" />
                <img src={triangleCard} className="card__forme card__forme--triangle" alt="" />
            </>}
            <img src={img}
                className={`card-img-${imgStyle}`} alt="..." />
            <div className={`card-body ${cardstyle}`}>
                <div>
                    {hrStyle && <>
                        <hr className={"card__separator " + hrclass} />
                    </>}
                </div>
                <h5 className={`card-title ${titleStyle ? titleStyle : ''}`}>{title}</h5>
                <p className={`card-text mt-4 ${pStyle ? pStyle : ''}`}>{text}</p>
            </div>
        </div>

    );

}

export default Box;
