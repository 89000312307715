import { useEffect, useState } from "react";
import Triangle from "../../assets/img/halftriangle.png"

const InfoCard = ({ children, title, logo, background, Orangeblur, Blueblur, underlineStyle }) => {
    const [active, setActive] = useState(false);
    const [activebutton, setActiveButton] = useState(false);
    const handleClick = () => {
        setActiveButton((prev) => !prev);
    }

    const getBackgroundsm = () => {
        return `linear-gradient(0deg, rgba(42, 46, 98, ${activebutton ? "0.8" : "1"}) 0%, rgba(42, 46, 98, ${activebutton ? '1' : '0'}) ${activebutton ? '100%' : '50%'}), url(${background})`
    }
    const [animCurrent, setAnimCurrent] = useState({ direction: 0, current: 0 });
    const handleMouseEnter = () => {
        setAnimCurrent(prev => ({ ...prev, direction: 1 }))
        setActive((prev) => !prev);
    }
    const handleMouseLeave = () => {
        setAnimCurrent(prev => ({ ...prev, direction: -1 }))
        setActive((prev) => !prev);
    }

    useEffect(() => {
        if (animCurrent.direction === 1) {
            if (animCurrent.current >= 100) {
                setAnimCurrent({ direction: 0, current: 100 })
            } else {
                setTimeout(() => {
                    setAnimCurrent(prev => ({ ...prev, current: prev.current + 1 }))
                }, (3));
            }
        } else if (animCurrent.direction === -1) {
            if (animCurrent.current <= 0) {
                setAnimCurrent({ direction: 0, current: 0 })
            } else {
                setTimeout(() => {
                    setAnimCurrent(prev => ({ ...prev, current: prev.current - 1 }))
                }, (3));
            }
        }
    }, [animCurrent])

    const getBackground = () => {
        return `linear-gradient(0deg, rgba(42, 46, 98, ${1 - (0.2 * animCurrent.current / 100)}) 0%, rgba(42, 46, 98, ${animCurrent.current / 100}) ${50 + (50 * animCurrent.current / 100) + "%"}), url(${background})`
    }


    return (
        <>
            <div className="row d-none d-lg-block position-relative">
                <div className="col-12 pb-5" style={{ zIndex: 1 }}>
                    <div style={{
                        borderRadius: "70px",
                        backgroundSize: "cover",
                        backgroundPosition: "center, top right",
                        backgroundRepeat: "no-repeat",
                        backgroundImage: getBackground(),
                    }
                    } className="position-relative"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}>
                        <div className={`info-content p-5 pb-0 ${active ? "active" : ""}`}>
                            {children}
                        </div>
                        <img src={Triangle} className="triangle" style={{ backgroundSize: "auto", top: "0px", right: "3px", position: "absolute", opacity: `${1 - (animCurrent.current / 100)}` }} alt="triangle" />
                        <div className="d-flex justify-content-between pb-5">
                            <h1 className={` ${underlineStyle} text-white fw-bolder mt-auto ms-5`}>{title}</h1>
                            <div className="position-relative me-4" style={{ width: "150px", height: "155px" }}>
                                <img
                                    src={logo}
                                    className="logoRight"
                                    alt="logo"
                                    role="button"
                                />
                                <div role="button" className={`plus ${active ? 'active' : ''}`}>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {Orangeblur ? <img src={Orangeblur} alt="orange-blur" className="orangeShadow" /> : ''}
                {Blueblur ? <img src={Blueblur} alt="blue-blur" className="blueShadow" /> : ''}
            </div>


            <div className="row d-lg-none position-relative">
                <div className="col-12 pb-5" style={{ zIndex: 1 }}>
                    <div style={{
                        borderRadius: "70px",
                        backgroundSize: "cover",
                        backgroundPosition: "center, top right",
                        backgroundRepeat: "no-repeat",
                        backgroundImage: getBackgroundsm(),
                    }
                    } className="position-relative">
                        <div className={`info-content-sm p-5 pb-0 ${activebutton ? "active" : ""}`}>
                            {children}
                        </div>
                        <div className="d-flex justify-content-between pb-5">
                            <h3 className={` ${underlineStyle} text-white fw-bolder mt-auto ms-5`}>{title}</h3>
                            <div className="position-relative me-4" style={{ width: "150px", height: "155px" }}>
                                <img
                                    src={logo}
                                    className="logoRight"
                                    alt="logo"
                                    role="button"
                                    onClick={handleClick}
                                />
                                <div role="button" className={`plus ${active ? 'active' : ''}`}>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <img src={Orangeblur} alt="orange-blur" className="orangeShadow" />
                <img src={Blueblur} alt="blue-blur" className="blueShadow" />
            </div>
        </>
    );
}

export default InfoCard;