import { PageTitle, Container } from "../components/base"
import AsidePage from "../components/base/AsidePage";
import { Section1 } from "../components/nosPartenaires"
const NosPartenaires = () => {
    return (

        <Container>
            <div className="row" style={{ margin: 20 }}>
                <PageTitle title="nos partenaires" subTitle="notre réseau" hideTitle={true} />
            </div>
            <AsidePage mobileFull={true} title="PARTENAIRES" fixed_title_bottom="fixed-title-nousrejoindre" pageComponent={<Section1 />} scroll={0.5} />
        </Container>


    );
}

export default NosPartenaires;