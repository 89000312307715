import Blur_bleu from "../../assets/img/blur-bleu.png"
import Blur_orange from "../../assets/img/blur-orange.png"
import LogoMarketing from "../../assets/img/marketingWhite.png"
import LogoWeb from "../../assets/img/webLogoWhite.png"
import LogoMobile from "../../assets/img/mobileLogoWhite.png"
import LogoGraphiste from "../../assets/img/graphisteLogoWhite.png"
import DevMobile from "../../assets/img/devMobile.png"
import Graphiste from "../../assets/img/graphistebackgrnd.png"
import Marketing from "../../assets/img/marketing.png"
import DevWeb from "../../assets/img/devWeb.png"
import InfoCard from "../design/InfoCard"
import LogoServicePedago from "../../assets/img/logoServicePedago.png"
import ServicePedago from "../../assets/img/servicePedagogique.png"

const Section1 = () => {

    return (
        <>
            <InfoCard title="Graphiste" logo={LogoGraphiste} background={Graphiste} underlineStyle="job-underline" Orangeblur={Blur_orange}>
                <p className="text-white text-reduce">
                    Les graphistes et webdesigners chez Educacode sont de véritables professionnels créatifs qui possèdent une grande expertise en matière d'infographie et de conception d'interfaces utilisateur (UX et UI design).<br />
                    <br /> Ils travaillent en étroite collaboration avec les autres pôles pour comprendre les besoins et concevoir des supports visuels attractifs, le tout en se mettant à la place des utilisateurs. Les graphistes garantissent une cohérence graphique entre les divers canaux de communication, qu'ils soient digitaux (sites internet, applications mobiles, réseaux sociaux, etc.) ou print (brochures, stickers pour voiture, impressions grand format …). Cependant leur travail ne se limite pas à la création de designs esthétiques, ils accordent une importance à l'ergonomie pour offrir une expérience utilisateur fluide et intuitive.<br />
                </p>
            </InfoCard>

            {/* marketing */}

            <InfoCard title="Marketing" logo={LogoMarketing} underlineStyle="job-underline" background={Marketing}>
                <p className="text-white">
                    Les membres du pôle marketing ont pour mission de développer et de mettre en œuvre des stratégies marketing et de communication efficaces mêlant créativité et connaissances pour toucher notre public cible.<br /><br />
                    Experts en rédaction, community management et communication, ils imaginent et créent des contenus pertinents et engageants pour l'ensemble des entreprises du groupe et veillent à la transmission d'un message clair et cohérent sur tous les supports de communication. Ils sont également en charge de la veille concurrentielle afin de s'assurer que les stratégies mises en place sont toujours en phase et répondent bien aux besoins des utilisateurs.
                    <br />

                </p>
            </InfoCard>

            {/* dev mobile */}

            <InfoCard title="Développement mobile" logo={LogoMobile} background={DevMobile} underlineStyle="mobileUnderline" Blueblur={Blur_bleu}>
                <p className="text-white">
                    Nos développeurs mobiles sont des experts dans le développement d'applications mobiles performantes, intuitives et sécurisées. Leur expertise permet de concevoir des applications accessibles aux utilisateurs n'importe où et à tout moment.
                    <br /><br />
                    En intégrant les maquettes conçues par les graphistes et webdesigners, ils créent des interfaces utilisateurs responsives adaptées à divers supports (smartphones, tablettes, etc.) et s'assurent de leur qualité en mettant en place des tests rigoureux pour détecter et corriger les éventuels bugs. De plus, ils se doivent de rester à l'affût des dernières tendances en matière de technologie mobile, en effectuant une veille constante pour proposer des solutions innovantes et à la pointe de ce qui se fait actuellement.

                </p>
            </InfoCard>
            {/* dev web */}

            <InfoCard title="Développement web" logo={LogoWeb} underlineStyle="mobileUnderline" background={DevWeb} >
                <p className="text-white">
                    Les développeurs web jouent un rôle essentiel dans la stratégie d'Educacode pour rendre nos solutions accessibles à un public toujours plus large. Ils ont pour mission de gérer les projets de développement web de bout en bout, en se concentrant sur les deux aspects clés du métier : le front-end et le back-end.
                    <br /><br />
                    En collaboration avec les graphistes, ils garantissent la compatibilité des sites internet avec les maquettes dans l'objectif de proposer une expérience utilisateur fluide et cohérente. Ils sont également chargés de la partie serveur de nos sites web, ce qui inclut la gestion des bases de données ou encore les connexions aux API externes. Leur expertise technique et leur maîtrise des langages informatiques leurs permettent de créer des sites dynamiques et interactifs mais aussi rapides, fiables et sécurisés.


                </p>
            </InfoCard>
            {/* Service pédagogique */}
            <InfoCard title="Service pédagogique" logo={LogoServicePedago} underlineStyle="pedagogiqueUnderline" background={ServicePedago} blur={Blur_orange}>
                <p className="text-white">Le service pédagogique d'Educacode occupe une place centrale dans l'entreprise, étant donné que toutes nos missions gravitent autour de lui. Les membres de ce pôle ont pour mission de concevoir et rédiger l'ensemble des cours proposés par l'entreprise.
                    <br /><br />
                    Ils se doivent de choisir la méthode d'enseignement la plus adaptée en utilisant des supports variés tels que des vidéos, des supports textuels, des images et des exercices d'entrainements. Ils veillent à ce que les leçons s'adaptent à tous et sont responsables de la qualité, de la cohérence et de la conformité réglementaire des enseignements proposés par Educacode. Le tout pour que les apprenants bénéficient d'une expérience pédagogique enrichissante et pertinente.
                </p>
            </InfoCard>

        </>

    );
}
export default Section1;