import IMG from "../../assets/img/import.png"
import { useContext } from "react"
import { MyContext } from "../../context/AppContext"
const FileInput = ({ type, name, displayFiles, requiredText, verificationText, valid, empty, label, value, accept, onChange, pattern, fileName, active, title, ismultiple, classname, id, required = 1 }) => {
    if (!valid) {
        empty = true
        requiredText = verificationText
    }

    const [store, setStore] = useContext(MyContext)
    const handleMouseEnter = () => {
        setStore({ ...store, cursor: true });
    }
    const handleMouseLeave = () => {
        setStore({ ...store, cursor: false });
    }
    return (
        <div className={`file form-control position-relative ${active ? 'active' : ''} ${empty ? 'empty' : ''} `}  >
            <div className="d-flex justify-content-center align-items-center" style={{ flexDirection: "column" }} >

                <input
                    id={id}
                    type={type}
                    accept={accept}
                    name={name}
                    onChange={onChange}
                    pattern={pattern}
                    className={classname}
                    value={value ? value : ""}
                    required={required}
                    autoComplete="off"
                    multiple={ismultiple}
                    title={title}

                />
                <span className={`floating-label ${active ? 'active' : ''}`}>{label} {empty}</span>
                <label htmlFor={id} className="text-center" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ cursor: "pointer" }}>
                    <img src={IMG} className="mt-5 mb-lg-1 mb-3" alt="File" />
                    <h6 className="mt-2">Déposez votre fichier ici</h6>
                    <h6 className="fw-light font-size">{fileName ? fileName : "Formats acceptés PDF, JPG, PNG"}</h6>
                </label>
            </div>
            {displayFiles}
            {empty && <span className="tooltip">{requiredText}</span>}
        </div>

    );
}

export default FileInput;