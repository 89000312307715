import { Button } from "../form";

const BlueCard = ({ Title, Description, LabelButton1, onClick1, LabelButton2, onClick2 }) => {
    return (
        <div className="blueCard">
            <div className="card-titre">
                <h1>{Title}</h1>
                <div className="line-blue-card my-4">
                </div>
            </div>

            <p>
                {Description}
            </p>
            <div className="d-lg-flex align-items-center d-block">
                <Button label={LabelButton1} onClick={onClick1} styleType="transparent-button" />
                <Button label={LabelButton2} onClick={onClick2} styleType="blue-button mt-2 mt-lg-0" />
            </div>
        </div>
    );
}

export default BlueCard;