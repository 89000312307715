import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  Route, Routes, unstable_HistoryRouter as HistoryRouter
} from "react-router-dom";

import history from "./history";
import AppContext from './context/AppContext';

import { Header, Footer, Theme, ScrollUp, ScrollToTop } from './components/base';
import { Contact, Home, NotFound, NosMetiers, Equipe, NousRejoindre, SiteEnConstruction, MentionsLegales, NosPartenaires, NosProjets, JobOffer } from './routes';
import { Scroll } from './components/design';
import { ROUTES } from "./utils";
import Mouse from './components/design/Mouse';

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <HistoryRouter history={history}>
      <ScrollToTop>
        <AppContext>
          <Theme>
            <Mouse />
            <Header />
            <Routes>
              <Route path={ROUTES.HOME} element={<Home />} />
              <Route path={ROUTES.NOTFOUND} element={<NotFound />} />
              <Route path={ROUTES.CONTACT} element={<Contact />} />
              <Route path={ROUTES.NOSMETIERS} element={<NosMetiers />} />
              <Route path={ROUTES.NOUSREJOINDRE} element={<NousRejoindre />} />
              <Route path={ROUTES.CONSTRUCTION} element={<SiteEnConstruction />} />
              <Route path={ROUTES.NOSPROJETS} element={<NosProjets />} />
              <Route path={ROUTES.NOSPARTENAIRES} element={<NosPartenaires />} />
              <Route path={ROUTES.EQUIPE} element={<Equipe />} />
              <Route path={ROUTES.MENTIONSEGALES} element={<MentionsLegales />} />
              <Route path={ROUTES.POLITIQUES} element={<SiteEnConstruction />} />
              <Route path={ROUTES.CONDITIONSGENERALES} element={<SiteEnConstruction />} />
              <Route path={ROUTES.COOKIES} element={<SiteEnConstruction />} />
              <Route path={ROUTES.JOBOFFER} element={<JobOffer />} />
            </Routes>
            <ScrollUp />
            <Scroll />
            <Footer />
          </Theme>
        </AppContext>
      </ScrollToTop>

    </HistoryRouter>

  </React.StrictMode>
);
