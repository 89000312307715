import { Link } from ".";
import linkedin from "../../assets/img/linkedin.svg";
import { ROUTES } from "../../utils";
import { useState } from "react";
import { Collapse } from "../design";

const Footer = () => {
  const [active, setActive] = useState(false);
  const [navigation, setNavigation] = useState(false);
  const [legales, setLegales] = useState(false);
  const [contact, setContact] = useState(false);
  const handleClick = () => {
    setActive((prev) => !prev);
  }
  const handleNavigation = () => {
    setNavigation((prev) => !prev);
  }
  const handleLegale = () => {
    setLegales((prev) => !prev);
  }
  const handleContact = () => {
    setContact((prev) => !prev);
  }

  return (
    <div className="container-fluid footer bg-primary">
      <div className="row px-5 text-white ">
        <div className="col-12 col-lg-3 titles footer-transition" >
          <p className="fw-bold submenu-title position-relative" onClick={() => handleClick()}>À propos <span className={`  arrow ${active ? 'aPropos' : ''}`} ></span></p>

          <Collapse className="collapsable d-grid d-lg-block" active={active}>
            <p>Educacode est une entreprise <br /> spécialisée dans la digitalisation <br /> des formations.
            </p><br />
            <p className={`copyright  ${active ? 'd-none' : 'showSubMenu'}`}><br /><br />© 2023 - Educacode</p>
          </Collapse>

        </div>

        <div className="col-12 col-lg-3 mb-3 footer-transition" >
          <p className="fw-bold submenu-title position-relative" onClick={() => handleNavigation()}>Navigation <span className={`  arrow ${navigation ? 'navigation' : ''}`} ></span></p>
          <Collapse active={navigation} className="collapsable d-grid d-lg-block">
            <ul className={`list-unstyled`}>
              <Link to={ROUTES.HOME}>Accueil</Link><br />
              <Link to={ROUTES.NOSPROJETS}>Nos projets</Link><br />
              <Link to={ROUTES.NOSMETIERS}>Nos métiers</Link><br />
              <Link to={ROUTES.EQUIPE}>L'équipe</Link><br />
              <Link to={ROUTES.NOUSREJOINDRE}>Nous rejoindre</Link><br />
              <Link to={ROUTES.NOSPARTENAIRES}>Nos partenaires</Link>
            </ul>
          </Collapse>
        </div>

        <div className="col-12 col-lg-3 mb-3 footer-transition" >
          <p className="fw-bold submenu-title position-relative" onClick={() => handleLegale()}>Légales <span className={`  arrow ${legales ? 'legale' : ''}`} ></span></p>
          <Collapse active={legales} className=" collapsable d-grid d-lg-block">
            <ul className={`list-unstyled`}>
              <Link to={ROUTES.MENTIONSEGALES}>Mentions légales</Link><br />
            </ul>
          </Collapse>
        </div>


        <div className="col-12 col-lg-3 mb-3 footer-transition" >
          <p className="fw-bold submenu-title position-relative" onClick={() => handleContact()}>Contact <span className={`  arrow ${contact ? 'contact' : ''}`} ></span></p>
          <Collapse active={contact} className="collapsable d-grid d-lg-block">
            <ul className={`list-unstyled`}>
              <Link external href="https://goo.gl/maps/MTWK1C5sLSztLdYZ9" target="_blank" label="9 Rue du Cerf-Volant " /><br />
              <Link external href="https://goo.gl/maps/MTWK1C5sLSztLdYZ9" target="_blank" label="17000 La Rochelle" /><br /><br />
              <Link external href="tel:062216352145614" label="Tél : +33 2 51 50 07 72" /> <br />
              <Link external href="mailto:contact@educacode.com" label="Mail : contact@educacode.com" /> <br />
              <Link external href="https://www.linkedin.com/company/educacode" target="_blank" label={<img
                src={linkedin} width="22px" alt="Linkedin Educacode" className={` d-lg-block`} />} />
            </ul>
            <div className="d-flex align-items-center justify-content-between ">
              <p className={`copyright d-lg-none `}><br /><br />© 2023 - Educacode</p>
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );

}

export default Footer;