import { Section1 } from "../components/nosMetiers"
import { PageTitle, Container } from "../components/base"
import AsidePage from "../components/base/AsidePage";
const NosMetiers = () => {
    return (

        <Container>
            <div className="row" style={{ margin: 20 }}>
                <PageTitle title="NOS MÉTIERS" subTitle="notre savoir-faire" />
            </div>

            <AsidePage mobileFull={true} title="NOS MÉTIERS" pageComponent={<Section1 />} />
        </Container>

        // <Section1 />
    );
}

export default NosMetiers;