import Summary from "./Summary";
import DevImg from "../../assets/img/developpement.png"
import { Candidature } from ".";
import Circle from "../../assets/img/ellipse.png"
import Rectangle from "../../assets/img/rectangle.png"
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Api from "../../api";


const Poste = () => {
    const { id } = useParams();

    const [job, setJob] = useState({
        baseline: "",
        contrat: "",
        date_creation: 0,
        description: "",
        localisation: "",
        duration: 0,
        mission: "",
        profil: "",
        title: "",
        delta: 0,
    })


    useEffect(() => {
        let now = new Date().getTime()
        Api.get(`/job/${id}`).then(response => {
            if (response.status === 200) {
                setJob({ ...response.data, delta: Math.round((Math.round(now / 1000) - response.data.date_creation) / (3600 * 24)) })
            }
        })
    }, [id])



    return (
        <>
            <div className="row  ms-lg-5  application" style={{ color: "#2a2e62" }}>
                <div className="col-lg-4 col-12 order-2 order-lg-2" style={{ zIndex: "2" }}>
                    <Summary location={job.localisation} contract={job.contrat} duration={job.duration} date={job.delta}></Summary>
                </div>
                <div className="col-lg-8 col-12 order-1 order-lg-2" style={{ zIndex: "3" }}>
                    <div className="py-5 ps-5 mt-5"
                        style={{
                            borderRadius: "40px",
                            backgroundSize: "cover",
                            minHeight: "46vh",
                            backgroundRepeat: "no-repeat",
                            backgroundImage: `url(${DevImg})`

                        }}>
                        <h1 className=" ps-lg-5 ps-2 pt-5 fw-bold" style={{ color: "#2a2e62" }}>{job.title}</h1>
                        <h4 className="ps-lg-5 ps-2" style={{ color: "#2a2e62" }}>{job.baseline}</h4>
                    </div>

                </div>
                <div className="d-lg-flex flex-column order-3 align-items-end">
                    <div className="col-lg-8 col-12" style={{ zIndex: "2" }}>
                        <div className="py-5 ps-5 mt-4 position-relative" style={{ borderRadius: "50px", backgroundColor: "#D2D4F9" }}>
                            <img src={Circle} className="form-circle" alt="Circle" />
                            <h2>Mission</h2>
                            <p>{job.mission}</p>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12" style={{ zIndex: "2" }}>
                        <div className="py-5 ps-5 mt-4 position-relative" style={{ borderRadius: "50px", backgroundColor: "#D2D4F9" }}>
                            <img src={Rectangle} className="form-rectangle" alt="Rectangle" />
                            <h2>Profil</h2>
                            <p>{job.profil}</p>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 my-4" style={{ zIndex: "2" }}>
                        <Candidature jobId={id} />
                    </div>
                </div>
            </div>



        </>
    );
}

export default Poste;