import Blur_orange from "../../assets/img/blur-orange.png"
import pc from "../../assets/img/pcConstruction.png"
import phone from "../../assets/img/constructionPhone.png"
import { ROUTES } from "../../utils";
import { Button } from "../form";
import { useNavigate } from "react-router-dom";
const Construction = () => {
    let navigate = useNavigate();

    function buttonClick() {
        navigate(ROUTES.CONTACT);
    }

    return (
        <div className="row mt-5 position-relative d-flex justify-content-between" style={{ zIndex: "2" }}>
            <div className="col-12 col-lg-5 pb-5 order-2 order-lg-1">
                <div className="px-lg-5">
                    <img src={Blur_orange} alt="Blur_orange" className="img-100" style={{ position: "absolute", bottom: "-480px", left: "-450px", zIndex: -1, pointerEvents: "none" }} />

                    <h2 className="subtitle pt-4 pb-2">On vous promet que ça vaut le coup d'attendre.</h2>

                    <p className="pb-4 presentation">Nous travaillons actuellement sur notre nouveau site web pour vous offrir une expérience en ligne améliorée, nous sommes désolés pour le dérangement temporaire.<br />
                        En attendant, n'hésitez pas à nous contacter si vous avez des questions ou des commentaires. Nous sommes là pour vous aider et nous vous remercions de votre patience.</p>
                    <Button type="button" label="Nous contacter" styleType="orange-ctr mb-5 px-5" onClick={buttonClick}
                    />
                </div>
            </div>
            <div className="col-12 pt-5 d-flex d-lg-block justify-content-center align-item-center col-lg-5 order-1 order-lg-2">
                <img src={pc} alt="pc" className="img-100 d-none d-lg-block " />
                <img src={phone} alt="phone" className="img-100  d-lg-none " />
            </div>
        </div>
    );
}

export default Construction;