import gris from "../../assets/img/fond-gris.png"
import topCircle from "../../assets/img/top-circle.png"
import circle from "../../assets/img/circle.png"
import bottomCircle from "../../assets/img/bottom-circle.png"
import Informations from "./Informations"


const Membre = ({ nom, poste, favPhrase, favStuff, favMoment, img }) => {
    return (
        <>
            <div className="col-lg-4 col-12 my-4 d-lg-block d-flex justify-content-center align-items-center flex-column" style={{ zIndex: "2" }}>
                <div className="avatar">
                    <div className="avatar_container">
                        <img src={gris} className="avatar_circle" alt="Background-sombre" />
                        <img src={topCircle} className="avatar_top_orange_circle" alt="Top-half-circle" />
                        <img src={bottomCircle} className="avatar_bottom_orange_circle" alt="Bottom-Half-circle" />
                        <img src={`data:image/png;base64,${img}`} className="avatar_img" alt="avatar" />
                    </div>
                </div>
                <div className="d-lg-none px-3 px-lg-0">
                    <div className="membre-vmobile">
                        <img src={circle} className="circle" alt="circle" />
                        <img src={`data:image/png;base64,${img}`} className="avatar-vmobile" alt="avatar-mobilev" />
                    </div>
                </div>
                <div className="mx-1 mx-lg-0" style={{ maxWidth: "18rem" }}>
                    <Informations favPhrase={favPhrase} favMoment={favMoment} favStuff={favStuff} nom={nom} poste={poste} />
                </div>
            </div>


        </>
    );
}

export default Membre;