
const Burger = ({ toggleMenu, active }) => {
    // const [active, setActive] = useState(false);
    const handleClick = () => {
        active = !active
        toggleMenu();
    }
    return (<div role="button" className={`burger ${active ? 'active fixBurger' : ''}`} onClick={handleClick}>
        <span></span>
        <span></span>
        <span></span>
    </div>);
}

export default Burger;