import { Button } from "../form";
import Suitcase from "../../assets/img/suitcase.png"
import Clock from "../../assets/img/clock.png"
import Location from "../../assets/img/location.png"
import Triangle from "../../assets/img/triangle.png"

const Summary = ({ location, contract, duration, date }) => {
    return (
        <div className="my-5 py-4 position-relative" style={{ backgroundColor: "#D2D4F9", borderRadius: "30px" }}>
            <h4 className="text-center">Résumé</h4>
            <div className="d-flex flex-column ps-4 pb-4">
                <div className="img-100">
                    <img src={Location} className="pe-2" alt="Location" />
                    <span>{location}</span>
                </div>
                <div className="img-100">
                    <img src={Suitcase} className="pe-2 py-2" alt="Suitcase" />
                    <span>{contract}</span>
                </div>
                <div className="img-100">
                    <img src={Clock} className="pe-2" alt="Clock" />
                    <span>{duration}</span>
                </div>
                <img src={Triangle} className="summary-triangle" alt="Triangle" />
            </div>
            <a href="#candidature" className="text-decoration-none">
                <Button type="button" label="Postuler" styleType="transparent postulerButton  mb-2 mx-auto"></Button></a>
            <span className="ps-4" style={{ color: "#707070", fontSize: "1.1rem" }}>Publié il y a {date} jours</span>
        </div>
    );
}

export default Summary;