import IeMob from "../../assets/img/logo-iemob.png";
import Actiroute from "../../assets/img/logo-actiroute.png";
import Cefr from "../../assets/img/logo-cefr.png";
import { Link } from "../base"
const Section4 = () => {
    return (
        <div className="container-fluid px-0">
            <div className="row bg-primary my-5 d-flex justify-content-center align-items-center py-5">
                <div className="col-12 col-lg-4 bg-light p-5 radius m-5 logo-iemob">
                    <img src={IeMob} width="100%" height="auto" alt="Logo Ie-Mob" />
                </div>
                <div className="col-12 col-lg-4 px-5 groupe mx-4">
                    <h2 className="subtitle">Le groupe IE-Mob</h2>
                    <p className="mb-1">Educacode est la dernière entreprise du groupe IE-Mob qui rassemble différentes entreprises spécialisées dans la sécurité routière et le permis de conduire.</p>
                    <p>Nous sommes fiers de faire partie d'un groupe convaincu que la technologie peut contribuer à améliorer la sécurité routière et nous sommes heureux de participer à la digitalisation des différents programmes de formation proposés par les autres entreprises du groupe, que sont :</p>
                    <div className="div-logo">
                        <Link external href="https://www.autoecole-cefr.fr/" target="_blank" label={<img
                            src={Cefr} width="80%" alt="Logo CEFR" height="auto" />} />
                        <Link external href="https://www.actiroute.com/" target="_blank" label={<img
                            src={Actiroute} width="80%" alt="Logo Actiroute" height="auto" />} />

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Section4;