import { Section1, Section2, Section3, Section4, Section5 } from "../components/home";

const Home = () => {
  return (
    //  SECTION 1
    <>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
    </>
  );
}

export default Home;