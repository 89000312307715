import { useEffect, useState } from "react";

const Input = ({ type, name, label, value, onChange, pattern, title, style, ismultiple, classname, id, required = 1 }) => {
  const [active, setActive] = useState(false);
  const handleFocus = () => setActive(true);
  const handleBlur = () => value ? setActive(true) : setActive(false);
  const [validity, setValidity] = useState(false)
  const handleInvalid = (e) => {
    e.preventDefault();
    setValidity(true);
  }
  const handleInput = () => {
    setValidity(false);

  }

  useEffect(() => {
    if (value) {
      setActive(true);
    }
  }, [value])

  return (
    <div className={`form-group my-3 ${type !== "file" ? "position-relative" : ""}`}>
      <input
        id={id}
        type={type}
        name={name}
        pattern={pattern}
        className={` ${classname} form-control ${active ? 'active' : ''} ${validity ? 'empty' : ''}`}
        value={value ? value : ""}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={onChange}
        required={required ? true : false}
        autoComplete="off"
        multiple={ismultiple}
        title={title}
        onInvalid={(e) => handleInvalid(e)}
        onInput={handleInput}
      />
      <span className={`floating-label ${active ? 'active' : ''}`}>{label}</span>
      {validity && <span className="tooltip">{required}</span>}
    </div>
  )
}

export default Input;