import { useNavigate } from "react-router-dom";
import { Link } from ".";
import { ROUTES } from "../../utils";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "../../scss/index.scss";
import { Button } from "../form";
import { Switch } from "../form";
import { Burger, MenuItem } from "."
import { useLocation } from "react-router-dom";
import logoEducacode from "../../assets/img/logo-educacode.svg";
import { useState } from "react";
import { Collapse } from "../design";

const Header = () => {
  const [menu, setMenu] = useState(false);
  const toggleMenu = () => {
    setMenu((prev) => !prev);
  }

  function buttonClick() {
    navigate(ROUTES.CONTACT);
    setMenu(false)
  }

  let navigate = useNavigate();
  let location = useLocation();
  let menuItemList = [

    {
      name: "Nos projets",
      link: ROUTES.NOSPROJETS,
    },
    {
      name: "Nos métiers",
      link: ROUTES.NOSMETIERS,
    },
    {
      name: "L'équipe",
      link: ROUTES.EQUIPE,
    },
    {
      name: "Nous rejoindre",
      link: ROUTES.NOUSREJOINDRE,
    },
    {
      name: "Nos partenaires",
      link: ROUTES.NOSPARTENAIRES,
    }
  ]
  return (

    <div className="bg-primary container-fluid">
      <nav className="bg-primary px-5 py-lg-4 mb-0 py-3" id="top-ref">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <Link className={`${menu ? '' : ''}`} to={ROUTES.HOME}>
              <img src={logoEducacode} height="95px"
                width="auto" alt="Logo Educacode" className="logo-educacode-header pe-5" />
            </Link>
            <ul className="navBar mb-0 d-none align-items-center d-lg-flex">
              {menuItemList.map((elem, index) => <MenuItem closeBurger={() => setMenu(false)} name={elem.name} link={elem.link} isActive={location.pathname === elem.link ? 'active' : ''} key={index} />)}
            </ul>
          </div>
          <Burger toggleMenu={toggleMenu} active={menu} />
          <div className="d-none d-lg-flex align-items-center justify-content-center">
            <Button type='button' label="Contact" styleType={`orange-ctr navButton me-3`} onClick={buttonClick} />
            <Switch className={`${menu ? '' : 'show'}`}></Switch>
          </div>
        </div>


        <div className={`ml-xl-5 d-block d-lg-flex justify-content-between`} >
          <Collapse className="collapsableNav d-grid d-lg-none" active={menu}>
            <div className={`${menu ? 'whiteBackgrd' : 'whiteBackgrdTrans'}`}></div>
            <div className={`opacityTransition ${menu ? 'fixedNav ' : 'transitionBack'}`}>
              <ul className="navbar-nav pb-3">
                <MenuItem closeBurger={() => setMenu(false)} name="Accueil" link={ROUTES.HOME} isActive={location.pathname === ROUTES.HOME ? 'active' : ''} />
                {menuItemList.map((elem, index) => <MenuItem closeBurger={() => setMenu(false)} name={elem.name} link={elem.link} isActive={location.pathname === elem.link ? 'active' : ''} key={index} />)}
              </ul>
              <div className="d-flex justify-content-center">
                <Button type='button' label="Contact" styleType={`orange-ctr px-5 py-1 me-3 ${menu ? '' : ''}`} onClick={buttonClick} />
                <Switch className={`${menu ? '' : ''}`}></Switch>
              </div>
            </div>
          </Collapse>
        </div>
      </nav>
    </div>
  );
}

export default Header;