import pause from "../../assets/img/pause.png"
import heart from "../../assets/img/heart.png"
import human from "../../assets/img/human-icon.png"
import { useState } from "react"
import { Collapse } from "../design"
import { useContext } from 'react';
import { MyContext } from '../../context/AppContext';
const Informations = ({ nom, poste, favPhrase, favStuff, favMoment }) => {
    const [infos, setInfos] = useState(false)
    const [active, setActive] = useState(false)
    const handleClick = () => {
        setActive(!active)
        setInfos(!infos)
    }
    const [store, setStore] = useContext(MyContext);

    const handleMouseEnter = () => {
        setStore({ ...store, cursor: true });
    }
    const handleMouseLeave = () => {
        setStore({ ...store, cursor: false });
    }
    return (
        <div className="ms-4 ms-lg-2 mt-3 col-10 description" >
            <div className="">
                <div className="bg-primary d-flex py-2 ps-4 align-items-center personal-infos" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ borderRadius: "15px" }} onClick={handleClick}>
                    <img src={human} height="24px" alt="human" />
                    <div className="d-flex flex-column justify-content-between ps-4 pe-5">
                        <span className="fw-bolder">{nom}</span>
                        <span style={{ fontSize: "16px" }}>{poste}</span>
                    </div>
                    <div role="button" className={`plusIcone ${active ? 'active' : ''}`}  >
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
            <div className={`ps-4`}>
                <Collapse className="collapsable d-grid employee-text pe-2" active={infos}>
                    {/* <div className="mt-3" >
                        <img src={backward} height="25px" />
                        <span className="fw-bolder ps-4">{favPhrase}</span><br />
                    </div> */}
                    <div className="mt-3">
                        <img src={heart} alt="heart" height="23px" />
                        <span className="fw-bolder ps-4">{favStuff}</span><br />
                    </div>
                    <div className="my-3">
                        <img src={pause} alt="pause" height="30px" />
                        <span className="fw-bolder ps-4">{favMoment}</span><br />
                    </div>
                </Collapse>
            </div>
        </div>
    );
}

export default Informations;