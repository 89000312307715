
import { useContext } from "react";
import { MyContext } from "../../context/AppContext";

const Button = ({ type, styleType, label, onClick }) => {
  const [store, setStore] = useContext(MyContext)
  const handleMouseEnter = () => {
    setStore({ ...store, cursor: true });
  }
  const handleMouseLeave = () => {
    setStore({ ...store, cursor: false });
  }

  return (
    <button type={type} className={`btn btn-${styleType ? styleType : 'primary'} `} onClick={onClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <span >{label}</span>
    </button>
  )
}

export default Button;